import { Directive, ElementRef, Renderer2, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appImg]'
})
export class ImgDirective {

  constructor(private el: ElementRef, private render: Renderer2) { }

  @HostListener('mouseenter') OnEnter() {
    this.render.setStyle(this.el.nativeElement, 'box-shadow', '0px 0px 15px #1dbaef')
    this.render.setStyle(this.el.nativeElement, 'cursor', 'pointer')
  }

  @HostListener('mouseleave') OnLeave() {
    this.render.removeStyle(this.el.nativeElement, 'box-shadow')
    this.render.removeStyle(this.el.nativeElement, 'cursor')
  }


}

