import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { StationsInfoService, Station, StationImage } from 'app/shared/services/stations-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { IFilterParameters } from '../filter/filter.component';
import { SubjectService } from 'app/shared/services/subject.service';
import { TranslateService } from '@ngx-translate/core';

// const AMOUNT_OF_VISIBLE_ON_PAGE = 15;
// const MESSAGE = 'Извините, станций с такимим критериями не найдены!';
// const TITLE = 'Фильтр не сработал.';

export interface IPlacemark {
  coordinates: number[];
  placemarkProperties: {
    hintContent: string
    balloonContentBody?: string
    balloonContentHeader?: string
    balloonContentFooter?: string
    balloonPanelMaxMapArea?: number
  };
  placemarkOptions: {
    iconColor: string
  }
}


@Component({
  selector: 'app-station-charact',
  templateUrl: './station-charact.component.html',
  styleUrls: ['./station-charact.component.scss'],
})
export class StationCharactComponent implements OnInit {


  geometrys: IPlacemark[] = [];
  placemarkOptions = {
    iconLayout: 'default#image',
    iconImageHref: 'assets/img/elements/placemark.svg',
    iconImageSize: [25, 40]
  }
  clusterer = {
    clusterIconLayout: 'default#pieChart',
    clusterOpenBalloonOnClick: true,
    clusterBalloonContentLayout: 'cluster#balloonCarousel',
    clusterBalloonContentLayoutWidth: 250,
    clusterBalloonContentLayoutHeight: 250,
    hasBaloon: false
  };
  filterProp: IFilterParameters;

  @ViewChild('accordion', {static: false})
  accordion: ElementRef;

  showFilter = false;
  allStations: Array<Station> = new Array<Station>();
  paginationArray: Station[] = [];
  isShowTable = window.localStorage.getItem('Viwe') === 'stationsPage.tableView' ? false : true;
  isShowAddStation = true;
  isShowMap = true;
  btnText: String = window.localStorage.getItem('Viwe') != null ? window.localStorage.getItem('Viwe') : 'stationsPage.tableView'
  DEFAULT_URL_IMG = 'assets/img/gallery/station_image.PNG'
  connectors: string[]
  @Output() selectedStation = new EventEmitter<Station>()
  stationsInfo: Station[] = []
  IsCheked = false
  isSerialNumber = false
  removeSearchCity = false
  removeSearchSerialNumber = false
  onlyNotActive = false;

  constructor(
      public sub: SubjectService,
      private stationsInfoServices: StationsInfoService,
      private router: Router,
      private translate: TranslateService) {
    this.btnText = window.localStorage.getItem('Viwe') !== null
        ? window.localStorage.getItem('Viwe')
        : 'tableViewText'
  }

  ngOnInit(): void {
    this.allStations.length = 0;
    this.stationsInfoServices.getStationsNew()
        .subscribe(response => {

      if (window.location.href.indexOf('onlyNotActive') !== -1) {
        this.onlyNotActive = true;
      }

      let onlyCharging = false;
      if (window.location.href.indexOf('onlyCharging') !== -1) {
        onlyCharging = true;
      }
      let bufStations: Station[] = [];
      if (onlyCharging || this.onlyNotActive) {

        if (onlyCharging) {
          bufStations = response.filter(s => {
            if (s.connectors) {
              if (s.connectors.filter(p => p.state === 3).length !== 0) {
                return s
              }
            }
          })
        }

        if (this.onlyNotActive) {
          bufStations = response.filter(s => s.state === 2)
          bufStations.sort(function (a, b) {
            if (a.last_online > b.last_online) {
              return -1;
            }
            if (a.last_online < b.last_online) {
              return 1;
            }
            return 0;
          });
        }
        this.allStations = bufStations;
        this.stationsInfo = bufStations;
      } else {
        this.allStations = response;
        this.stationsInfo = response;
      }
      this.setGeometry();
    });
  }

  updateFilter(stations: Station[]) {
    this.allStations = stations
    this.setGeometry()
    this.isShowMap = !this.isShowMap
  }

  placeMarkClick($event) {
    const coordinates = $event.instance.geometry._coordinates;
    const station = this.stationsInfo.find(s => {
      return s.latitude === coordinates[0] && s.longitude === coordinates[1]
    });
    this.SelectedStation(station.id)
  }

  setBaloonContent = (imgs: StationImage[], id: number): string => {
    const styleImg = 'width:100%; height:140px; border-radius: 10px'
    const styleWrap = 'display:flex; justify-content: center; aligin-items: center; cursor: pointer'
    //<img src="${imgs !== null ? imgs[0].url : this.DEFAULT_URL_IMG}" style="${styleImg}"/>
    return `<div style="${styleWrap}">
              <img src="${this.DEFAULT_URL_IMG}" style="${styleImg}"/>
            </div>`
  }
  setBaloonHeader = (id: number, street: string): string => {
    const styleWrap = 'display:flex; justify-content: center; aligin-items: center; flex-direction: column'
    return `<div style="${styleWrap}">
        <p style="color: black">ID: ${id}</p>
        <p>${street}</p>
    </div>`
  }
  setBaloonFooter = (station: Station): string => {
    const styleWrap = 'display:flex; justify-content: center; aligin-items: center; flex-direction: column'
    return `<div style="${styleWrap}">
      <p style="color: black">${this.translate.instant('powerText')}: ${station.powerAC} kWt</p>
      <p style="color: black">${this.translate.instant('stationText.text')}: ${station.state === 1
        ? this.translate.instant('activeText')
        : this.translate.instant('notActiveText')}</p>
      <a href="#/station-page/${station.id}">Перейти</a>
    </div>`
  }

  setGeometry() {
    console.log(this.allStations)
    if (this.allStations) {
      this.geometrys = this.allStations.map(s => {
        const geometry: IPlacemark = {
          coordinates: [s.latitude, s.longitude],
          placemarkProperties: {
            hintContent: s.street,
            balloonContentBody: this.setBaloonContent(s.images, s.id),
            balloonContentHeader: this.setBaloonHeader(s.id, s.street),
            balloonContentFooter: this.setBaloonFooter(s),
            balloonPanelMaxMapArea: 0
          },
          placemarkOptions: {
            iconColor: s.state === 1 ? '#1DD300' : '#FF0000'
          },
        }
        return geometry
      })
  }
}

  SelectedStation(id: number) {
    this.router.navigate(['station-page', id]);
  }

  onChangePage(pageOfItems: Station[]) {
    this.paginationArray = pageOfItems;
    this.SetConnectorInfo(this.paginationArray);
  }

  public removeStation($event, stationId: number) {
    swal.fire({
      title: 'Вы уверены?',
      text: 'Вы хотите удалить эту станцию?',
      type: 'warning',
      cancelButtonText: 'Нет!',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да, уверен!'
    }).then((result) => {
      if (result.value) {
        this.stationsInfoServices.deleteStation(stationId)
          .subscribe(data => {
            if (data['success']) {
              swal.fire(
                'Удалена!',
                'Станция была успешно удалена!',
                'success'
              )
            }
          });
      }
    });
  }

  public GetConnectors(state: number, type: string, meterState: number): string {
    if (meterState === -1) {
      return `${type}` + '_reserved.png';
    } else {
      if (type === 'gbt') {
        console.log(type)
        console.log(state)
      }
      switch (state) {
        case 252:
        case 3: {
          return `${type}` + '_busy.png';
        }
        case 0:
        case 255:
        case 55: {
          return (`${type}` + '_notAvailable.png');
        }
        case 1:
        case 254: {
          return (`${type}` + '_free.png');
        }
      }
    }
  }

  public GetStateConnectors(state: number): string {
    switch (state) {
      case 0:
      case 255: {
        return 'Недоступен';
      }
      case 55: {
        return 'Не в сети';
      }
      case 1: {
        return 'Подготовка';
      }
      case 254: {
        return 'Доступен'
      }
      case 252: {
        return 'Зарядка завершина'
      }
      case 3: {
        return 'Идёт зарядка'
      }
    }
  }


  private SetConnectorInfo(array: Station[]) {
    for (let index = 0; index < array.length; index++) {
      if (this.paginationArray[index].connectors != null) {
        for (let i = 0; i < this.paginationArray[index].connectors.length; i++) {
          const state: number = this.paginationArray[index].connectors[i].state;
          this.paginationArray[index].connectors[i].imgUrl = this.GetConnectors(state,
            this.paginationArray[index].connectors[i].type.toLowerCase(),
            this.paginationArray[index].connectors[i].meterState);
          this.paginationArray[index].connectors[i].infoText = 'Состояние порта: ' + this.GetStateConnectors(state);
        }
      }
    }
  }

  public changeTypeSearch(type: string): void {
    if (type === 'city') {
      if (!this.removeSearchCity) {
        this.isSerialNumber = false
        this.IsCheked = true
        this.removeSearchCity = true
      } else {
        this.IsCheked = false
        this.removeSearchCity = false
      }
    } else {
      if (!this.removeSearchSerialNumber) {
        this.isSerialNumber = true
        this.IsCheked = false
        this.removeSearchSerialNumber = true
      } else {
        this.isSerialNumber = false
        this.removeSearchSerialNumber = false
      }
    }
  }

  public search(value: string): void {
    this.paginationArray = [];
    if (value) {
      this.paginationArray = this.stationsInfo
        .filter(station => {
            return station.street.toLowerCase().includes(value.toLowerCase())
                || station.serial === value
                || station.city.toLowerCase().includes(value.toLowerCase())
                || station.name === value
        })
      this.allStations = this.paginationArray
    } else {
      this.allStations = this.stationsInfo
    }
  }

  public ShowTableView() {
    if (this.isShowTable) {
      this.isShowTable = false;
      this.btnText = 'tableViewText';
      window.localStorage.setItem('Viwe', 'stationsPage.tableView');
    } else {
      this.isShowTable = true;
      this.isShowAddStation = true;
      this.isShowMap = true;
      this.btnText = 'panelViewText'
      window.localStorage.setItem('Viwe', 'stationsPage.panelView');
    }
  }

  public OpenMapDialog() {
    if (!this.isShowMap) {
      this.isShowMap = true;
    } else {
      this.isShowAddStation = true;
      this.isShowTable = false;
      this.isShowMap = false;
    }
  }

  public OpenAddStationDialog() {
    if (!this.isShowAddStation) {
      this.isShowAddStation = true;
    } else {
      this.isShowAddStation = false;
      this.isShowTable = false;
      this.isShowMap = true;
    }

  }
}

export class StationInfo {
  idStation: string;
  typeStation: string;
  street: string;
  stateStation: string;
}


