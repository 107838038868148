import { Injectable } from '@angular/core';
import { StationsInfoService } from './stations-info.service';
import { ToastrService } from 'ngx-toastr';

export interface IUpdateController {
  stationId: number,
  type: string,
  fileName: string,
  controllerId: number
}
@Injectable()
export class WorkWithPortService {
  constructor(private services: StationsInfoService, private toast: ToastrService) { }

  updateController(body: IUpdateController) {
    this.services.updateFirmware(body)
      .subscribe(data => {
        if (data['success']) {
          this.toast.success('Запрос обновления успешно обработан!');
        } else {
          this.toast.warning('Ошибка сервера!')
        }
      })
  }

  updateDisplay(_stationId: number, _type: string) {
    const body = {
      stationId: _stationId,
      type: _type
    }
    this.services.updateFirmware(body)
      .subscribe(data => {
        if (data['success']) {
          this.toast.success('Запрос обновления успешно обработан!');
        } else {
          this.toast.warning('Ошибка сервера!')
        }
      })
  }

  updateRpi(_stationId: number, _type: string) {
    const body = {
      stationId: _stationId,
      type: _type
    }
    this.services.updateFirmware(body)
      .subscribe(data => {
        if (data['success']) {
          this.toast.success('Запрос обновления успешно обработан!');
        } else {
          this.toast.warning('Ошибка сервера!')
        }
      })
  }
}
