import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';
import { StatisticsService, GetTransactionsRequest } from 'app/shared/services/statistics.service';
import { Transaction } from '../../../shared/services/statistics.service'
import {AllUser, AuthService} from '../../../shared/auth/auth.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {map, sample, startWith} from 'rxjs/operators';


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {


  myControl = new FormControl()
  options: string[] = ['One', 'Two', 'Three']
  filteredOptions: Observable<string[]>

  hoveredDate: NgbDate
  fromDate: NgbDate
  toDate: NgbDate
  isAccept = true
  dataSource
  paginationArray: Transaction[] = [];

  showListUser = false
  usersName: AllUser[] = []

  isAdmin = 'oper'

  @ViewChild('datepicker', { static: true }) datepicker: NgbInputDatepicker;
  @ViewChild('chooseCharging', {static: false}) chooseChargingRef: ElementRef<HTMLDivElement>
  @ViewChild('iconSortDown', {static: false}) iconSortDownRef: ElementRef<HTMLDivElement>
  @ViewChild('loginInput', {static: false}) loginInput: ElementRef<HTMLInputElement>
  @ViewChild('companyInput', {static: false}) companyInput: ElementRef<HTMLInputElement>
  @ViewChild('stateInput', {static: false}) stateInput: ElementRef<HTMLInputElement>
  @ViewChild('acquiringInput', {static: false}) acquiringInput: ElementRef<HTMLInputElement>


  constructor(private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
              private rendere2: Renderer2,
              private authService: AuthService,
    private service: StatisticsService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
  }

  ngOnInit() {
    this.isAdmin = window.localStorage.getItem('Role')
    /*
    this.authService.getAllUsers()
        .subscribe(response => {
          if (response.success) {
            this.usersName = response.users
          }
        })

    this.authService.getAllUsers()
        .subscribe(response => {
          if (response.success) {

            this.options =  response.users.map(a => a.login).sort((one, two) => (one > two ? 1 : -1))
            this.filteredOptions = this.myControl.valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );

          }
        })
*/

    this.authService.getAllUsers()
        .subscribe(response => {
          if (response.success) {
            this.usersName = response.users
          }
        })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  onChangePage(pageOfItems: Array<any>) {
    this.paginationArray = pageOfItems;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      this.isAccept = false;
      this.toDate = date;
      this.datepicker.close();
    } else {
      this.isAccept = false;
      this.toDate = null;
      this.fromDate = date;
      this.datepicker.close();
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input)
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  accept() {
    if ((this.fromDate || this.toDate)) {
      const begin = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
      const end = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
      const login = this.loginInput.nativeElement.value

      const state = Number(this.stateInput.nativeElement.value)

      let company = ''
      let acquiring = ''
      if(this.isAdmin === 'superadmin') {
        company = this.companyInput.nativeElement.value
        acquiring = this.acquiringInput.nativeElement.value
      }

      const body: GetTransactionsRequest = {
        begin,
        end,
        login,
        company,
        state,
        acquiring
      }

      this.service.getTransactions(body)
        .subscribe(resp => {
          console.log(resp)
          this.dataSource = resp.transactions
        })
    }
  }

  showUserListName() {
    this.showListUser = !this.showListUser
    this.changeBorderColor(this.showListUser)
    this.createBackground(this.usersName)
  }

  setUserName(name: string) {
    this.loginInput.nativeElement.value = name
    this.showListUser = false
    this.changeBorderColor(this.showListUser)
  }

  showSimilarNames(value: string, key: string) {
    const filterNames = this.usersName.filter(name => name.login.includes(value))
    if (!filterNames.length || key === 'Backspace') {
      const div = this.rendere2.selectRootElement('.bg')
      if (div) {
        this.rendere2.removeChild(document.body, div)
      }
      return
    }
    this.createBackground(filterNames)
  }

  changeBorderColor(focus: boolean) {
    if (focus) {
      this.rendere2.setStyle(this.chooseChargingRef.nativeElement, 'borderColor', '#1dbaef')
      this.rendere2.setStyle(this.iconSortDownRef.nativeElement, 'color', '#1dbaef')
    } else {
      this.rendere2.setStyle(this.chooseChargingRef.nativeElement, 'borderColor', '#bdbdc7')
      this.rendere2.setStyle(this.iconSortDownRef.nativeElement, 'color', '#bdbdc7')
    }
  }

  createBackground(users: AllUser[]) {
    const rect = this.chooseChargingRef.nativeElement.getBoundingClientRect() as ClientRect
    const div = this.rendere2.createElement('div')
    this.rendere2.addClass(div, 'bg')
    this.rendere2.listen(div, 'click', () => this.removeAllBgDivs())
    const listDivUserName = this.rendere2.createElement('div')
    this.rendere2.setStyle(listDivUserName, 'position', 'fixed')
    this.rendere2.setStyle(listDivUserName, 'top', `${rect.top + rect.height}px`)
    this.rendere2.setStyle(listDivUserName, 'left', `${rect.left}px`)
    this.rendere2.setStyle(listDivUserName, 'background', '#3c4a50')
    this.rendere2.setStyle(listDivUserName, 'display', 'flex')
    this.rendere2.setStyle(listDivUserName, 'flex-direction', 'column')
    this.rendere2.setStyle(listDivUserName, 'height', '100px')
    this.rendere2.setStyle(listDivUserName, 'width', `${rect.width}px`)
    this.rendere2.setStyle(listDivUserName, 'overflow-y', 'scroll')
    this.rendere2.setStyle(listDivUserName, 'overflow-x', 'hidden')
    this.rendere2.setStyle(listDivUserName, 'border-radius', '5px')
    users.forEach(user => {
      const button = this.rendere2.createElement('button')
      this.rendere2.addClass(button, 'button')
      this.rendere2.setProperty(button, 'textContent', user.login)
      this.rendere2.listen(button, 'click', () => {
        this.setUserName(user.login)
      })
      this.rendere2.appendChild(listDivUserName, button)
    })
    this.rendere2.appendChild(div, listDivUserName)
    this.rendere2.appendChild(document.body, div)
  }

  removeAllBgDivs() {
    const divs = document.getElementsByClassName('bg')
    for (const value of divs as any) {
      this.rendere2.removeChild(document.body, value)
    }
  }
}
