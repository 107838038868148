import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
import { UrlPath } from './urlPathConst'
import { ChangeUserDataRequest } from '../.././models/auth/ChangeUserDataRequest'
import { DeleteUserRequest } from '../.././models/auth/DeleteUserRequest'
import { TranslateService } from '@ngx-translate/core'
import {User} from '../../models/auth/User';
import {StationUser} from '../services/statistics.service';
import {Router} from '@angular/router';


@Injectable()
export class AuthService {

  access_token: string;
  refresh_token: string;
  headers: HttpHeaders = new HttpHeaders()
      .set('Authorization', this.getToken())
      .set('Content-Type', 'application/json');

  constructor(private http: HttpClient,
              private toastr: ToastrService,
              private translate: TranslateService,
              private router: Router) { }

  getBalanceHistory(request: GetBalanceHistoryRequest): Observable<GetBalanceHistoryResponse> {
    return this.http.get<GetBalanceHistoryResponse>(
        UrlPath.MONEY + `web/user-balance-history?login=${request.login}&balance=${request.balance}`,
        {
          headers: this.headers
        })
  }
  getUsersBalances(login: GetUsersBalancesRequest): Observable<GetUsersBalancesResponse> {
    return this.http.get<GetUsersBalancesResponse>(
        UrlPath.MONEY + 'web/user-balances',
        {
          headers: this.headers,
          //params: {['login']: login.login }
        })
  }

  changeUserData(body: ChangeUserDataRequest): Observable<{ success: boolean, error: number }> {
    return this.http.post<{ success: boolean, error: number }>(UrlPath.BASE_URL + 'api/changeData', body, { headers: this.headers })
  }

  deleteUser(body: any): Observable<DeleteUserRequest> {
    return this.http.post<DeleteUserRequest>(UrlPath.BASE_URL + 'api/delete', body, { headers: this.headers })
  }

  addNewUser(body: any) {
    return this.http.post(UrlPath.BASE_URL + 'web/new', body, { headers: this.headers })
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(UrlPath.BASE_URL + 'web/my-users', { headers: this.headers })
      .pipe(
        map(data => { return data['users'] }));
  }

  public getAllUsers(): Observable<GetAllUsersResponse> {
    return  this.http.get<GetAllUsersResponse>(UrlPath.BASE_URL + 'web/all-users', {
      headers: this.headers,
    })
  }

  subscribeToTopic(body: SubscribeTopicRequest): Observable<boolean> {
    return this.http.post(UrlPath.BASE_URL + 'api/subscribeToTopic', body, { headers: this.headers })
      .pipe(map(response => response['success']))
  }

  signinUser(login: string, password: string): Observable<Boolean> {
    const body = { Login: login, Password: password };
    return this.http.post(UrlPath.BASE_URL + 'web/sign-in', body, { responseType: 'json' })
      .pipe(map(data => {
        if (data['success'] === true) {
          window.localStorage.setItem('AccessToken', data['access_token']);
          window.localStorage.setItem('RefreshToken', data['refresh_token']);
          window.localStorage.setItem('ExpireDate', data['expire_date']);
          this.access_token = data['access_token'];
          this.refresh_token = data['refresh_token'];
          this.headers = new HttpHeaders().set('Authorization', this.getToken()).set('Content-Type', 'application/json');
          return true;
        } else {
          let errorMessage = ''
          this.translate.get('loginFailed').subscribe((resp: string) => errorMessage = resp)
          this.toastr.error(errorMessage);
          return false;
        }
      }));
  }

  getUserData(): Observable<Boolean> {
    return  this.http.get(UrlPath.BASE_URL + 'web/data', {
      headers: this.headers,
    }).pipe(map(data => {
      console.log(data);
      if (data['success'] === true) {
        window.localStorage.setItem('Login', data['login']);
        window.localStorage.setItem('Email', data['email']);
        window.localStorage.setItem('Latitude', data['latitude']);
        window.localStorage.setItem('Longitude', data['longitude']);
        window.localStorage.setItem('Role', data['role']);
        // this.headers = new HttpHeaders().set('Authorization', this.getToken()).set('Content-Type', 'application/json');
        return true;
      } else {
        let errorMessage = ''
        this.translate.get('loginFailed').subscribe((resp: string) => errorMessage = resp)
        this.toastr.error(errorMessage);
        return false;
      }
    }));
  }

  logout() {
    this.access_token = null;
    window.localStorage.removeItem('Email');
    window.localStorage.removeItem('AccessToken');
    window.localStorage.removeItem('RefreshToken');
    window.localStorage.removeItem('Login');
    window.localStorage.removeItem('Latitude');
    window.localStorage.removeItem('Longitude');
    window.localStorage.removeItem('Role');
    this.router.navigate(['login']);
  }


  async getTokenN() {
    this.access_token = localStorage.getItem('AccessToken');
    console.log("start");
    if (Number(localStorage.getItem('ExpireDate')) < Math.round(+new Date() / 1000) + 60) {
      const body = { access_token: this.access_token, refresh_token: this.refresh_token };
      const t = await this.http.post(UrlPath.BASE_URL + 'web/refresh-token', body, { responseType: 'json' }).toPromise()
      console.log("stop");
    }
    console.log("return");
    return this.access_token;
  }

  getToken() {
    this.access_token = localStorage.getItem('AccessToken');
    return this.access_token;
  }
  isAuthenticated() {
    if (this.getToken()) {
      return true
    } else { return false }
  }

  saveUserFreeAccess(body: SaveUserFreeAccessRequest): Observable<SaveUserFreeAccessResponse> {
    return this.http.post<SaveUserFreeAccessResponse>(
        UrlPath.SERVICE_STATISTICS + 'web/free-access',
        body,
        {
          headers: this.headers
        })
  }

  userFreeAccess(userId: string): Observable<GetStationsFreeAccessResponse> {
    return this.http.get<GetStationsFreeAccessResponse>(UrlPath.SERVICE_STATISTICS + 'web/free-access',
        {
          headers: this.headers,
          params: {UserId : userId}
        })
  }
}

export interface GetStationsFreeAccessRequest {
  userId: string
}

export interface  GetStationsFreeAccessResponse {
  success: boolean,
  stations: StationUser[]
}

export interface SaveUserFreeAccessRequest {
  user_id: number,
  stations: number[]
}

export interface SaveUserFreeAccessResponse {
  success: boolean
}

export interface GetAllUsersResponse {
  success: boolean
  errorCode: number,
  users: AllUser[]
}

export interface AllUser {
  id: number,
  login: string
}

export interface GetBalanceHistoryRequest {
  login: string,
  balance: string
}

export interface  GetBalanceHistoryResponse {
  success: boolean,
  histories: History[]
}

export interface History {
  value: number,
  login: string,
  operationTime: string,
  rest: number,
  type: number
}

export interface SubscribeTopicRequest {
  token: string,
}

export interface GetUsersBalancesRequest {
  login: string
}

export interface GetUsersBalancesResponse {
  success: boolean
  usersBalances: UserBalance[]
}
export interface UserBalance {
  login: string
  type: string
  value: number
}

