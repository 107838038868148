import { Directive, Input, HostListener, HostBinding, OnInit, ElementRef, Renderer2,NgModule } from '@angular/core';

@Directive({
  selector: '[appTooltipImg]'
})
export class TooltipImgDirective implements OnInit {

  @Input() textTooltip = ""

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {}

  @HostListener("mouseenter") onMouseEnter(){
    this.renderer.setAttribute(this.elementRef.nativeElement, "matTooltip", "sdfdf");
  }

}

@NgModule({
  declarations: [TooltipImgDirective],
  exports: [TooltipImgDirective]
})
export class TooltipImgModule { }
