import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginPageComponent } from './pages/content-pages/login/login-page.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: '', component: FullLayoutComponent, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'pages/error' }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
