import { NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AppRoutingModule } from './app-routing.module'
import { SharedModule } from './shared/shared.module'
import { ToastrModule } from 'ngx-toastr'
import { AgmCoreModule } from '@agm/core'
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http'
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { StoreModule } from '@ngrx/store'
import { FormsModule } from '@angular/forms'
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar'

import { AppComponent } from './app.component'
import { FullLayoutComponent } from './layouts/full/full-layout.component'

import { DragulaService } from 'ng2-dragula'
import { AuthService } from './shared/auth/auth.service'
import { AuthGuard } from './shared/auth/auth-guard.service'
import { ContentPagesModule } from './pages/content-pages/content-pages.module'
import { DatePipe } from '@angular/common'
import { MessagingService } from './shared/services/messaging.service'
import { AngularFireAuthModule } from '@angular/fire/auth'
import {ComponentsModule} from './components/components.module';
import {TokenInterceptor} from './shared/auth/token.interceptor';





const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AngularFireAuthModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyAl7cGmi01CbxhGBmn9pCmlsk0G8OzwZ28'}),
    PerfectScrollbarModule,
    FormsModule,
    ContentPagesModule,
    ComponentsModule
  ],
  providers: [
    MessagingService,
    AuthService,
    AuthGuard,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TokenInterceptor
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
