import { Directive, Input, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[ngInit]'
})
export class NgInitDirective {


    @Output('ngInit') initEvent: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.initEvent.emit();
    }
}