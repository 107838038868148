import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  ViewChild
} from '@angular/core'
import { ConfigService } from 'app/shared/services/config.service'
import { DOCUMENT } from '@angular/common'
import { LayoutService } from 'app/shared/services/layout.service'
import { Subscription } from 'rxjs'

const fireRefreshEventOnWindow = function () {
  const evt = document.createEvent('HTMLEvents');
  evt.initEvent('resize', true, false);
  window.dispatchEvent(evt);
};

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',

})
export class FullLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidebarBgImage', { static: true }) sidebarBgImage: ElementRef;
  @ViewChild('appSidebar', { static: true }) appSidebar: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  options = {
    direction: 'ltr',
    bgColor: 'black',
    bgImage: 'assets/img/sidebar-bg/07.jpg'
  };
  hideSidebar: boolean;
  layoutSub: Subscription;
  iscollapsed = true;
  isSidebar_sm = false;
  isSidebar_lg = false;
  bgColor = 'black';
  bgImage = 'assets/img/sidebar-bg/07.jpg';

  public config: any = {};

  constructor(
    private elementRef: ElementRef,
    private layoutService: LayoutService,
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {

  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.bgColor = this.config.layout.sidebar.backgroundColor;

    setTimeout(() => {
      if (this.config.layout.sidebar.size === 'sidebar-lg') {
        this.isSidebar_sm = false;
        this.isSidebar_lg = true;
      } else if (this.config.layout.sidebar.size === 'sidebar-sm') {
        this.isSidebar_sm = true;
        this.isSidebar_lg = false;
      } else {
        this.isSidebar_sm = false;
        this.isSidebar_lg = false;
      }
      this.iscollapsed = this.config.layout.sidebar.collapsed;
    }, 0);

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.dir) {
        this.options.direction = this.config.layout.dir;
      }

      if (this.config.layout.variant === 'Dark') {
        this.renderer.addClass(this.document.body, 'layout-dark');
      } else if (this.config.layout.variant === 'Transparent') {
        this.renderer.addClass(this.document.body, 'layout-dark');
        this.renderer.addClass(this.document.body, 'layout-transparent');
        if (this.config.layout.sidebar.backgroundColor) {
          this.renderer.addClass(
            this.document.body,
            this.config.layout.sidebar.backgroundColor
          );
        } else {
          this.renderer.addClass(this.document.body, 'bg-glass-1');
        }
        this.bgColor = 'black';
        this.options.bgColor = 'black';
        this.bgImage = '';
        this.options.bgImage = '';
        this.bgImage = '';
        this.renderer.setAttribute(
          this.sidebarBgImage.nativeElement,
          'style',
          'background-image: none'
        );
      }
    }, 0);


  }


  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'layout-dark');
    this.renderer.removeClass(this.document.body, 'layout-transparent');
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClick() {
    setTimeout(() => {
      fireRefreshEventOnWindow();
    }, 300);
  }

  toggleHideSidebar($event: boolean): void {
    setTimeout(() => {
      this.hideSidebar = $event;
    }, 0);
  }

  getOptions($event): void {
    this.options = $event;
  }
}


