import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {StatisticsService, UserChargeResponse} from '../../../shared/services/statistics.service'
import {DatePipe} from '@angular/common';


enum TarifficationEnum {
    ['Незвестная тарификация'],
    ['Тарификация по времени'],
    ['Тарификация по кВт/ч']
}

enum TarifficationForEnum {
    ['Незвестная тарификация'],
    ['мин'],
    ['кВТ/ч']
}

@Component({
    selector: 'app-statistics-dialog',
    templateUrl: './statistics-dialog.component.html',
    styleUrls: ['./statistics-dialog.component.scss']
})
export class StatisticsDialogComponent implements OnInit {
    userCharge: UserChargeResponse
    beginCharge = ''
    endCharge = ''
    durationTime = ''
    tariffication = ''
    priceFor = ''

    constructor(@Inject(MAT_DIALOG_DATA) private data: { id: string },
                private statisticService: StatisticsService,
                private datePipe: DatePipe) {
    }

    ngOnInit() {
        this.statisticService.getUserCharge(this.data.id)
            .subscribe(response => {
                const {begin, end} = response
                this.userCharge = response
                this.beginCharge = this.datePipe.transform(begin, 'h:mm:ss')
                this.endCharge = this.datePipe.transform(end, 'h:mm:ss')
                this.durationTime = this.getDurationTime(begin, end)
                this.tariffication = TarifficationEnum[response.tariffication]
                this.priceFor = TarifficationForEnum[response.tariffication]
            })
    }

    getDurationTime(begin: string, end: string) {
        const beginDate = new Date(begin)
        const endDate = new Date(end)
        const dTime = endDate.getTime() - beginDate.getTime()
        const dDate = new Date(dTime)
        return `${dDate.getUTCHours()}ч ${dDate.getUTCMinutes()} мин ${dDate.getUTCSeconds()}с`
    }

}
