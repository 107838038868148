import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CustomValidators } from 'app/shared/validators/customValidators';
import { NewStation, StationsInfoService } from 'app/shared/services/stations-info.service';
@Component({
  selector: 'app-add-new-station',
  templateUrl: './add-new-station.component.html',
  styleUrls: ['./add-new-station.component.scss']
})
export class AddNewStationComponent {

  imgArray: Array<string> = Array<string>();

  myForm: FormGroup = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      CustomValidators.trimValidator
    ]),
    power: new FormControl(null, [
      Validators.required,
      CustomValidators.isNumberValue
    ]),
    country: new FormControl('', [
      Validators.required,
      CustomValidators.trimValidator,
      CustomValidators.forbiddenSymbols
    ]),

    city: new FormControl('', [
      Validators.required,
      CustomValidators.trimValidator,
      CustomValidators.forbiddenSymbols
    ]),
    street: new FormControl('', [
      Validators.required,
      CustomValidators.trimValidator,
      CustomValidators.forbiddenSymbols
    ]),
    currency: new FormControl(null),
    ip: new FormControl(null, [
      Validators.required,
      CustomValidators.isNumberValue,
    ]),
    port: new FormControl(null, [
      Validators.required,
      CustomValidators.isNumberValue,
    ]),
    latitude: new FormControl(null, [
      Validators.required,
      CustomValidators.isNumberValue,
    ]),
    longitude: new FormControl(null, [
      Validators.required,
      CustomValidators.isNumberValue,
    ]),
    priceMin: new FormControl(null),
    price: new FormControl(null),
    type: new FormControl(null, Validators.required),
    mapUrl: new FormControl(null),
    serial: new FormControl(null),
    utc: new FormControl(null),
    protocol: new FormControl({value: 'ocpp1.6j', disabled: true}),
    company: new FormControl(null)
  });

  constructor(private services: StationsInfoService) { }


  trim(name: string, value: string) {
    if (value) {
      this.myForm.get(name).setValue(value.trim())
    }
  }

  submit() {
    if (this.myForm.valid) {
      const body: NewStation = {
        city: this.myForm.get('city').value,
        company: this.myForm.get('company').value,
        country: this.myForm.get('country').value,
        currency: this.myForm.get('currency').value,
        images: this.imgArray,
        ip: this.myForm.get('ip').value,
        latitude: parseFloat(this.myForm.get('latitude').value),
        longitude: parseFloat(this.myForm.get('longitude').value),
        mapUrl: this.myForm.get('mapUrl').value,
        name: this.myForm.get('name').value,
        port: +this.myForm.get('port').value,
        power: +this.myForm.get('power').value,
        price: parseFloat(this.myForm.get('price').value),
        priceMin: parseFloat(this.myForm.get('priceMin').value),
        protocol: this.myForm.get('protocol').value,
        serial: this.myForm.get('serial').value,
        street: this.myForm.get('street').value,
        type: this.myForm.get('type').value,
        utc: +this.myForm.get('utc').value,
      }
      this.services.addNewStation(body);
    }
  }

  loadStationImage(e) {
    const reader = new FileReader();
    reader.onload = (event: Event) => {
      this.onLoadCallback(event);
    }
    reader.readAsDataURL(e.srcElement.files[0]);
  }

  onLoadCallback(event) {
    this.imgArray.push(event.target['result']);
  }

  removeImg(img: string) {
    for (const key in this.imgArray) {
      if (this.imgArray[key] === img) {
        this.imgArray.splice(+key, 1);
        break;
      }
    }
  }


}
