import { Component, OnInit, ElementRef, Input, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Station, StationsInfoService } from 'app/shared/services/stations-info.service';
import { Subscription, interval } from 'rxjs';


interface ChargingValue {
  isCharging: boolean[],
  btnText: string[],
  info: {
    connectorId: number[]
    index: number[]
  }
}

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit, OnDestroy {

  @ViewChildren('hour', { read: ElementRef }) hourRef: QueryList<ElementRef<HTMLDivElement>>
  @ViewChildren('futureHour') futureHourRef: QueryList<ElementRef<HTMLDivElement>>
  @ViewChildren('pastHour') pastHourRef: QueryList<ElementRef<HTMLDivElement>>

  @ViewChildren('min') minRef: QueryList<ElementRef<HTMLDivElement>>
  @ViewChildren('futureMinute') futureMinuteRef: QueryList<ElementRef<HTMLDivElement>>
  @ViewChildren('pastMinute') pastMinuteRef: QueryList<ElementRef<HTMLDivElement>>

  @ViewChildren('amperage') amperageRef: QueryList<ElementRef<HTMLInputElement>>
  @Input() stationId: number

  hours: number[] = Array(11).fill(1).map((_, i) => i)
  minuts: number[] = Array(60).fill(1).map((_, i) => i)
  private counterHour = 0
  private counterMinuts = 0
  sub: Subscription;
  station: Station
  isCharging: boolean[] = [false, false, false]
  btnText: string[] = ['Начать зарядку', 'Начать зарядку', 'Начать зарядку']

  constructor(private toast: ToastrService, private statisticsService: StationsInfoService) { }

  getState() {
    console.log('getState')
    this.statisticsService.getStationConnectors(this.stationId)
      .subscribe(data => {
        if (data != null) {
          data.forEach((v, i) => {
            this.isCharging[i] = v.state === 3 ? true : false
            this.btnText[i] = v.state === 3 ? 'Остановить зарядку' : 'Начать зарядку'
          })
        }
      });
  }

  ngOnInit() {
    this.sub = interval(20000).subscribe(() => this.getState());
    this.statisticsService.getStationInfo(this.stationId)
      .subscribe(response => this.station = response.station)
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  scrollHour($event: WheelEvent, connectorId: number) {
    if ($event.deltaY > 0) {
      if (this.counterHour < this.hours.length - 1) {
        ++this.counterHour
        const hour = this.hours[this.counterHour].toString()
        this.hourRef.toArray()[connectorId].nativeElement.textContent = hour
        this.futureHourRef.toArray()[connectorId].nativeElement.textContent = +hour === this.hours[this.hours.length - 1]
          ? ''
          : (+hour + 1).toString()
        this.pastHourRef.toArray()[connectorId].nativeElement.textContent = (+hour - 1) === -1 ? '0' : (+hour - 1).toString()
      }
    } else {
      if (this.counterHour > 0) {
        --this.counterHour
        const hour = this.hours[this.counterHour].toString()
        this.hourRef.toArray()[connectorId].nativeElement.textContent = hour
        this.futureHourRef.toArray()[connectorId].nativeElement.textContent = +hour === 0 ? '1' : (+hour + 1).toString()
        this.pastHourRef.toArray()[connectorId].nativeElement.textContent = (+hour - 1) === -1 ? '' : (+hour - 1).toString()
      }
    }
  }

  scrollMinites($event: WheelEvent, connectorId: number) {
    if ($event.deltaY > 0) {
      if (this.counterMinuts < this.minuts.length - 1) {
        ++this.counterMinuts
        const min = this.minuts[this.counterMinuts].toString()
        this.minRef.toArray()[connectorId].nativeElement.textContent = min
        this.futureMinuteRef.toArray()[connectorId].nativeElement.textContent = +min === 59 ? '' : (+min + 1).toString()
        this.pastMinuteRef.toArray()[connectorId].nativeElement.textContent = (+min - 1) === -1 ? '0' : (+min - 1).toString()
      }
    } else {
      if (this.counterMinuts > 0) {
        --this.counterMinuts
        const min = this.minuts[this.counterMinuts].toString()
        this.minRef.toArray()[connectorId].nativeElement.textContent = min
        this.futureMinuteRef.toArray()[connectorId].nativeElement.textContent = +min === 0 ? '1' : (+min + 1).toString()
        this.pastMinuteRef.toArray()[connectorId].nativeElement.textContent = (+min - 1) === -1 ? '' : (+min - 1).toString()
      }
    }
  }

  startCharging(index: number, connectorId: number) {
    if (!this.isCharging[index]) {
      const body = {
        hour: this.hourRef.toArray()[index].nativeElement.textContent !== '0'
          ? this.hourRef.toArray()[index].nativeElement.textContent
          : '',
        minute: this.minRef.toArray()[index].nativeElement.textContent !== '0'
          ? this.minRef.toArray()[index].nativeElement.textContent
          : '',
        amperage: this.amperageRef.toArray()[index].nativeElement.value !== '0'
          ? this.amperageRef.toArray()[index].nativeElement.value
          : ''
      }
      if (body.amperage.trim() && (body.hour.trim() || body.minute.trim())) {
        this.isCharging[index] = true
        this.btnText[index] = 'Остановить зарядку'
        const timeLited = (+body.hour * 3600) + (+body.minute * 60)
        this.statisticsService.setPortParameters({ StationId: this.stationId, ConnectorId: connectorId, Amperage: +body.amperage })
        // this.statisticsService.startCharging(this.stationId, connectorId, timeLited)
      } else {
        this.toast.warning('Выберете время и апераж для начало зарядки!')
      }
    } else {
      this.statisticsService.stopCharging(this.stationId, connectorId)
      this.btnText[index] = 'Начать зарядку'
      this.isCharging[index] = false
      this.amperageRef.toArray()[index].nativeElement.value = ''
      this.minRef.toArray()[index].nativeElement.textContent = '0'
      this.hourRef.toArray()[index].nativeElement.textContent = '0'
      this.pastHourRef.toArray()[index].nativeElement.textContent = ''
      this.pastMinuteRef.toArray()[index].nativeElement.textContent = ''
      this.futureHourRef.toArray()[index].nativeElement.textContent = ''
      this.futureMinuteRef.toArray()[index].nativeElement.textContent = ''
    }
  }

}
