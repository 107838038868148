import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {UrlPath} from './urlPathConst';
import 'rxjs/add/operator/delay';
import {catchError, filter, map, switchMap, take} from 'rxjs/operators';
import {BehaviorSubject, throwError} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient,
                private auth: AuthService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        console.log("before request");


        return next.handle(req).pipe(catchError((error) => {
            console.log(error.status)
            if (error instanceof HttpErrorResponse && !req.url.includes('sign-in') && error.status === 402) {
                return this.handle401Error(req, next);
            }
            return throwError(error);
        }));


    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const token = localStorage.getItem('AccessToken');

            if (token) {
                const body = {access_token: localStorage.getItem('AccessToken'), refresh_token: localStorage.getItem('RefreshToken')};
                console.log("before auth request");
                return this.http.post(UrlPath.BASE_URL + 'web/refresh-token', body, { headers: this.auth.headers }).pipe(
                    switchMap(data => {
                        this.isRefreshing = false;
                        console.log("auth response");
                        if (data['success'] === true) {
                            window.localStorage.setItem('AccessToken', data['access_token']);
                            window.localStorage.setItem('RefreshToken', data['refresh_token']);
                            window.localStorage.setItem('ExpireDate', data['expire_date']);
                            this.auth.headers = new HttpHeaders().set('Authorization', data['access_token']).set('Content-Type', 'application/json');
                            this.refreshTokenSubject.next(data['access_token']);

                            return next.handle(this.injectToken(request));
                        } else {
                            this.auth.logout();
                        }
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;

                        //this.tokenService.signOut();
                        this.auth.logout();
                        return throwError(err);
                    })
                );
            }
        } else {

            console.log("handle 8");
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap((token) => {
                    return next.handle(this.injectToken(request))
                })
            );
        }


    }

    injectToken(request: HttpRequest<any>) {
       return  request = request.clone({
            setHeaders: {
                Authorization: localStorage.getItem('AccessToken')
            }
        })
    }
}