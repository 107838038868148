import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { AngularFireMessaging } from '@angular/fire/messaging'
import { AngularFireFunctions } from '@angular/fire/functions'
import { AuthService, SubscribeTopicRequest } from '../auth/auth.service'



@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null)


  constructor(private angularFireMessaging: AngularFireMessaging, private fun: AngularFireFunctions, private auth: AuthService) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )


  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        console.log(token)
        this.auth.subscribeToTopic({ token })
          .subscribe(response => console.log(response))
      },
      err => console.log('Unable to get permission to notify.', err)
    )
  }

  receiveMessage() {
    this.angularFireMessaging.messaging.subscribe(
      (payload) => {
        console.log('new message received. ', payload);
        this.currentMessage.next(payload);
      })
  }
}
