import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AuthService, UserBalance, History} from '../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit {

  balances: UserBalance[] = []
  histories: History[] = []
  paginationHistories: History[] = []
  paginationBalance: UserBalance[] = []
  @ViewChild('menu', {static: false}) menuRef: ElementRef<HTMLElement>
  @ViewChild('outClick', {static: false}) outRef: ElementRef<HTMLElement>
  @ViewChild('historyBalance', {static: false}) historyBalanceRef: ElementRef<HTMLElement>
  login = ''
  balance = ''

  constructor(private render2: Renderer2,
              private authService: AuthService,
              private toast: ToastrService) { }

  ngOnInit() {
    this.authService.getUsersBalances({
      login: window.localStorage.getItem('Login')
    }).subscribe(response => {
      if (response.success) {
        this.balances = response.usersBalances
        // this.render2.setStyle(this.historyBalanceRef.nativeElement, 'visibility', 'visible')
      }
    }, error =>  console.log(error))
  }

  filter(text: string) {
    if (text.trim()) {
      const filterArrayByLogin = this.balances
          .filter(balance => balance.login.toLowerCase() === text.toLowerCase())
      this.onChangePage(filterArrayByLogin)
    } else {
      this.onChangePage(this.balances.slice(0, 10))
    }
  }

  onChangePageHistory(pageOfItems: Array<History>) {
    this.paginationHistories = pageOfItems;
  }

  onChangePage(pageOfItems: Array<UserBalance>) {
    this.paginationBalance = pageOfItems;
  }

  getBalance(event: MouseEvent, tr: HTMLTableRowElement) {
    event.preventDefault()
    this.login = tr.cells[0].innerText
    this.balance = tr.cells[1].innerHTML
    this.authService.getBalanceHistory({
      balance: this.balance,
      login: this.login
    })
        .subscribe(response => {
          if (response.success) {
            this.histories = response.histories
            this.render2.removeClass(this.menuRef.nativeElement, 'show')
            this.render2.setStyle(this.outRef.nativeElement, 'display', 'none')
          } else {
            this.toast.warning('У этого пользователя нету истории баланса.')
          }
        }, error => console.log(error))
  }

  showContextMenu(event: MouseEvent, tr: HTMLTableRowElement) {
    event.preventDefault()
    this.login = tr.cells[0].innerText
    this.balance = tr.cells[1].innerHTML
    this.render2.setStyle(this.menuRef.nativeElement, 'top', `${event.layerY}px`)
    this.render2.setStyle(this.menuRef.nativeElement, 'left', `${event.layerX}px`)
    this.render2.addClass(this.menuRef.nativeElement, 'show')
    this.render2.setStyle(this.outRef.nativeElement, 'display', 'block')
  }

  hideContextMenu() {
    this.render2.removeClass(this.menuRef.nativeElement, 'show')
    this.render2.setStyle(this.outRef.nativeElement, 'display', 'none')
    this.balance = ''
    this.login = ''
  }

  hideWindowHistory() {
    if (this.histories.length) {
      this.histories.length = 0
    }
  }

}
