import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {StatisticsDialogComponent} from './dialogs/statistics-dialog/statistics-dialog.component'
import {MatDialogModule} from '@angular/material/dialog';
import { InfoLineComponent } from './dialogs/statistics-dialog/info-line/info-line.component'
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        StatisticsDialogComponent,
        InfoLineComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        TranslateModule
    ],
    entryComponents: [StatisticsDialogComponent]
})
export class ComponentsModule {
}
