import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Station } from 'app/shared/services/stations-info.service';
import { MatExpansionPanel } from '@angular/material';
import { SubjectService } from 'app/shared/services/subject.service';

class TypeFilter {
  cсs: string
  chademo: string
  j1772: string
  _62196: string
}

export interface IFilterParameters {
  access: number,
  types: TypeFilter,
  ac: boolean,
  dc: boolean
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;
  @Input() showFilter: boolean;
  access = 0
  ac = false
  dc = false
  type2 = false
  type1 = false
  ccs = false
  chademo = false
  private filter: IFilterParameters;
  @Input() stations: Station[]
  @Output() prop: EventEmitter<IFilterParameters> = new EventEmitter<IFilterParameters>()
  @Output() update: EventEmitter<Station[]> = new EventEmitter<Station[]>()
  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(private sub: SubjectService) { }


  ngOnInit() {
    this.sub.subject$.subscribe(show => {
      show ? this.panel.open() : this.panel.close()
    })
  }

  setFilter() {
    this.filter = {
      ac: this.ac,
      access: +this.access,
      dc: this.dc,
      types: {
        _62196: this.type2 ? 'type2' : '',
        chademo: this.chademo ? 'chademo' : '',
        cсs: this.ccs ? 'ccs' : '',
        j1772: this.type1 ? 'type1' : ''
      }
    }

    let filterArray = this.filter.access !== 0 ? this.stations
      .filter(station => station.mode === this.filter.access) : this.stations
    console.log(filterArray.filter(s => s.is_ac))
    filterArray = this.setTypeFilter(filterArray)
    filterArray = (this.filter.ac || this.filter.dc) !== false ? filterArray.filter(station =>
      (station.is_ac === +this.filter.ac && station.is_dc === +this.filter.dc)) : filterArray
    this.prop.emit(this.filter)
    this.update.emit(filterArray)
  }


  private setTypeFilter(filterArray: Station[]) {
    const types = Object.values(this.filter.types).filter(type => type !== '')
    console.log(types)
    if (types.length !== 0) {
      return filterArray.filter(s => {
        if (s.connectors) {
          for (const key in s.connectors) {
            if (types.includes(s.connectors[key].type.toLowerCase())) {
              return s
            }
          }
        }
      })
    } else {
      return filterArray
    }
  }

  resetFilter() {
    this.panel.close()
    this.access = 0
    this.ac = false
    this.dc = false
    this.type2 = false
    this.type1 = false
    this.ccs = false
    this.chademo = false
    this.reset.emit(this.showFilter = !this.showFilter)
    this.prop.emit(this.filter = null)
    this.update.emit(this.stations)
  }

}
