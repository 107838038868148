export enum UrlPath {

    //BASE_URL = 'https://auth.ecars-service.by/',
    //SERVICE_STATISTICS = 'https://station.ecars-service.by/',
    //LOG = 'https://ecars-service.by:8084/',
    //MONEY = 'https://money.ecars-service.by/',
    //STATION_TEST_URL = 'https://station.ecars-service.by/'

    BASE_URL = 'https://auth.2cs-service.ru/',
    SERVICE_STATISTICS = 'https://station.2cs-service.ru/',
    LOG = 'https://event.2cs-service.ru/',
    MONEY = 'https://money.2cs-service.ru/',
    STATION_TEST_URL = 'https://station.2cs-service.ru/'

    //SSL protocol
         //BASE_URL = 'http://134.17.16.151:7081/',
         //SERVICE_STATISTICS = 'http://134.17.16.151:7082/',
         //LOG = 'http://134.17.16.151:7084/',
         //MONEY = 'http://134.17.16.151:7083/',
         //STATION_TEST_URL = 'http://134.17.16.151:8000/'

    //BASE_URL = 'http://5.187.2.61:7081/',
    //SERVICE_STATISTICS = 'http://5.187.2.61:7082/',
    //LOG = 'http://5.187.2.61:7084/',
    //MONEY = 'http://5.187.2.61:7083/',
    //STATION_TEST_URL = 'http://5.187.2.61:8000/'

    //BASE_URL = 'http://134.17.16.151:8001/',
    //SERVICE_STATISTICS = 'http://134.17.16.151:7082/',
    //LOG = 'http://134.17.16.151:7084/',
    //MONEY = 'http://134.17.16.151:7083/',
    //STATION_TEST_URL = 'http://134.17.16.151:8000/'

    ///http://185.26.99.14:8072/
    // test server
    // MONEY = 'https://test-ecars-service.xyz:8083/',
    // LOG = 'https://test-ecars-service.xyz:8084/',
    // SERVICE_STATISTICS = 'https://test-ecars-service.xyz:8082/',
    // BASE_URL = 'https://test-ecars-service.xyz:8081/',
}
