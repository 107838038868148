import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-line',
  templateUrl: './info-line.component.html',
  styleUrls: ['./info-line.component.scss']
})
export class InfoLineComponent implements OnInit {

  @Input() iconName = ''
  @Input() title = ''
  @Input() text = ''
  constructor() { }

  ngOnInit() {}

}
