import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'
import { Station } from './stations-info.service';

@Injectable({
  providedIn: 'root'
})
export class GetStationInfoServices {
  myMethod$: Observable<Station>;
  private myMethodSubject = new Subject<any>();

  constructor() {
    this.myMethod$ = this.myMethodSubject.asObservable();
  }

  getValue(station: Station) {
    this.myMethodSubject.next(station);
  }
}
