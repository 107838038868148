import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Observable} from 'rxjs/Observable'
import {map, delay} from 'rxjs/operators'
import {HttpClient, HttpParams} from '@angular/common/http';
import {UrlPath} from '../auth/urlPathConst';
import * as alertFunctions from '../../shared/data/sweet-alerts';
import {ToastrService} from 'ngx-toastr';
import {hebrewNumerals} from '@ng-bootstrap/ng-bootstrap/datepicker/hebrew/hebrew';

@Injectable({
    providedIn: 'root'
})
export class StationsInfoService {
    stationsArray: Array<Station> = new Array<Station>();

    constructor(private http: HttpClient,
                private authServices: AuthService,
                public toastr: ToastrService) {
    }


    changeConfiguration(body: ChangeConfigurationRequest): Observable<ChangeConfigurationResponse> {
        return this.http.post(UrlPath.STATION_TEST_URL + 'web/ocpp-param', body, {headers: this.authServices.headers})
            .pipe(map(data => {
                const resp: ChangeConfigurationResponse = {
                    success: data['success']
                }
                return resp
            }))
    }

    changeStationImages(id: number, images: string[]): Observable<boolean> {
        const body = {
            images: images,
            station_id: id
        }
        return this.http.post<boolean>(UrlPath.SERVICE_STATISTICS + 'web/change-station-images',
            body,
            {headers: this.authServices.headers})
    }

    requestLogs(_staionId: number): Observable<boolean> {
        return this.http.get(UrlPath.STATION_TEST_URL + `web/request-logs?StationId=${_staionId}`, {headers: this.authServices.headers})
            .pipe(map(data => {
                return data['success']
            }))
    }

    ping(_staionId: number): Observable<boolean> {
        return this.http.get(UrlPath.STATION_TEST_URL + `web/ping?StationId=${_staionId}`, {headers: this.authServices.headers})
            .pipe(map(data => {
                return data['success']
            }))
    }

    pingSimCard(_staionId: number): Observable<boolean> {
        return this.http.get(UrlPath.STATION_TEST_URL + `web/ping-simcard?StationId=${_staionId}`, {headers: this.authServices.headers})
            .pipe(map(data => {
                return data['success']
            }))
    }

    getLogs(_stationId: number): Observable<GetLogsResponse> {
        return this.http.get(UrlPath.LOG + `web/logs?station_id=${_stationId}`, {headers: this.authServices.headers})
            .pipe(map(response => {
                const logs: GetLogsResponse = {
                    success: response['success'],
                    logs: response['logs']
                }
                return logs
            }))
    }

    removeLog(body: RemoveLogRequest) {
        return this.http.post(UrlPath.LOG + `web/delete-log`, body, {headers: this.authServices.headers})
    }


    getConfiguration(_stationId: number): Observable<ConfigurationResponse> {
        return this.http.get(UrlPath.STATION_TEST_URL + `web/ocpp-params?StationId=${_stationId}`,
            {headers: this.authServices.headers})
            .pipe(
                map(data => {
                    const response: ConfigurationResponse = {
                        success: data['success'],
                        configuration: data['Configuration']
                    }
                    return response
                })
            )
    }

    getStationConnectors(_stationId?: number): Observable<CurrentPortState[]> {
        return this.http.get(UrlPath.STATION_TEST_URL + 'web/connectors?StationId='
            + `${_stationId}`, {headers: this.authServices.headers})
            .pipe(map(data => {
                if (data['success']) {
                    let connectors: CurrentPortState[] = [];
                    connectors = data['connectors'];
                    return connectors;
                } else {
                    console.log('api/stationConnectors - ERROR');
                }
            }));
    }

    getStations(): Observable<Station[]> {
        return this.http.get<StationsResponse>(UrlPath.STATION_TEST_URL + 'web/stations-with-connectors', {headers: this.authServices.headers})
            .pipe(map(response => {
                if (response.stations) {
                    return response.stations
                }
                return null
            }));
    }

    getStationsNew(): Observable<Station[]> {
        return this.http.get<StationsResponse>(UrlPath.STATION_TEST_URL + 'web/stations-with-connectors', {headers: this.authServices.headers})
            .pipe(map(response => {
                if (response.stations) {
                    return response.stations
                }
                return null
            }));
    }



    startCharging(body: StartChargingRequest): Observable<Answer> {
        return this.http.post<Answer>(
            UrlPath.STATION_TEST_URL + 'web/start-charging-session',
            body,
            {headers: this.authServices.headers}).pipe(map(response => {
            return response
        }));
    }

    stopCharging(stationId: number, connectorId: number) {
        const body = {StationId: stationId, ConnectorId: connectorId};
        return this.http.post(UrlPath.STATION_TEST_URL + 'web/stop-charging-session', body, {headers: this.authServices.headers})
            .toPromise().then(data => {
                if (data['success']) {
                    this.toastr.success('Зарядка была отключена!');
                } else {
                    this.toastr.warning('Ошибка сервера!')
                }
            });
    }

    addNewStation(body: NewStation) {
        this.http.post(UrlPath.SERVICE_STATISTICS + 'api/new', body, {headers: this.authServices.headers})
            .subscribe(data => {
                if (data['success']) {
                    alertFunctions.typeSuccess();
                }
            });
    }

    rebootStation(body: RebootStationRequest) {
        this.http.post(UrlPath.STATION_TEST_URL + 'web/reboot', body, {headers: this.authServices.headers})
            .subscribe(data => {
                if (data['success']) {
                    this.toastr.success('Станция перезагружается!');
                } else {
                    this.toastr.warning('Ошибка сервера!')
                }
            });
    }

    makeNotAvailablePort(_body: any) {
        this.http.post(UrlPath.STATION_TEST_URL + 'web/change-connector-state', _body, {headers: this.authServices.headers})
            .subscribe(data => {
                if (data['success']) {
                    this.toastr.success('Состояние порта было изменено!');
                } else {
                    this.toastr.warning('Ошибка сервера!')
                }
            });
    }

    setPortParameters(body: PortParametersRequest): Observable<boolean> {
        return this.http.post<boolean>(UrlPath.SERVICE_STATISTICS + 'api/setAmperage', body, {headers: this.authServices.headers})
    }

    updateStation(body: any) {
        this.http.post<boolean>(UrlPath.STATION_TEST_URL + 'web/station-info', body, {headers: this.authServices.headers})
            .subscribe(response => {
                if (response) {
                    this.toastr.success('Изменения были успешно применены!');
                }
            });
    }

    getStationInfo(stationId: number): Observable<StationResponse> {
        return this.http.get<StationResponse>(UrlPath.STATION_TEST_URL + `web/station?StationId=${stationId}`,
            {headers: this.authServices.headers});
    }

    getStationImages(stationId: number): Observable<StationImagesResponse> {
        return this.http.get<StationImagesResponse>(UrlPath.STATION_TEST_URL + `web/station-images?StationId=${stationId}`,
            {headers: this.authServices.headers});
    }

    getChargings(params: GetChargeStatisticRequest): Observable<any> {
        let url = UrlPath.STATION_TEST_URL + `web/charging-sessions?begin=${params.dateBegin}&end=${params.dateEnd}`
        if (params.login) {
            url += `&login=${params.login}`
        }
        if (params.id) {
            url += `&id=${params.id}`
        }
        if (params.stations) {
            params.stations.forEach(id => {
                url += `&stations=${id}`
            })
        }

        if (params.company) {
            url += `&company=${params.company}`
        }

        if (params.smth) {
            url += `&smth=${params.smth}`
        }

        if (params.connectorType) {
            url += `&connector_type=${params.connectorType}`
        }
        return this.http.get(url, {headers: this.authServices.headers})
    }

    getReport(params: GetChargeStatisticRequest): Observable<any> {
        let url = UrlPath.STATION_TEST_URL + `web/report?begin=${params.dateBegin}&end=${params.dateEnd}`
        if (params.login) {
            url += `&login=${params.login}`
        }
        if (params.id) {
            url += `&id=${params.id}`
        }
        if (params.stations) {
            params.stations.forEach(id => {
                url += `&stations=${id}`
            })
        }

        if (params.company) {
            url += `&company=${params.company}`
        }

        if (params.smth) {
            url += `&smth=${params.smth}`
        }

        if (params.connectorType) {
            url += `&connector_type=${params.connectorType}`
        }
        return this.http.get(url, {headers: this.authServices.headers})
    }

    deleteStation(id: number): Observable<any> {
        return this.http.post(UrlPath.SERVICE_STATISTICS + 'api/delete', {stationId: id}, {headers: this.authServices.headers});
    }

    updateFirmware(body: any): Observable<any> {
        return this.http.post(UrlPath.STATION_TEST_URL + 'web/update-firmware', body, {headers: this.authServices.headers});
    }

    getChargeReport(chargingId: string): Observable<GetChargeReportResponse> {
        const params = new HttpParams()
            .set('chargingId', chargingId)
        return this.http.get<GetChargeReportResponse>(
            UrlPath.SERVICE_STATISTICS + `api/getChargeReport`,
            {headers: this.authServices.headers, params})
    }

    getChargingStatistic(body: GetChargingStatisticRequest): Observable<GetChargingStatisticResponse> {
        const params = new HttpParams()
            .set('StationId', `${body.stationId}`)
            .set('ConnectorId', `${body.connectorId}`)
        return this.http.get<GetChargingStatisticResponse>(
            UrlPath.STATION_TEST_URL + 'web/change-charging-connector',
            {headers: this.authServices.headers, params}
        )
    }

    setMode(body: SetModeRequest): Observable<SetModeResponse> {
        return this.http.post<SetModeResponse>(UrlPath.SERVICE_STATISTICS + 'api/setMode', body, {headers: this.authServices.headers})
    }

    setConnectorChargeParams(body: SetConnectorChargeParamsRequest): Observable<SetConnectorChargeParamsResponse> {
        return this.http.post<SetConnectorChargeParamsResponse>(
            UrlPath.STATION_TEST_URL + 'web/connector-settings',
            body,
            {headers: this.authServices.headers})
    }

    setConnectorPrice(body: SetConnectorPriceRequest): Observable<SetConnectorChargeParamsResponse> {
        return this.http.post<SetConnectorChargeParamsResponse>(
            UrlPath.STATION_TEST_URL + 'web/connector-price',
            body,
            {headers: this.authServices.headers})
    }

    getConnectorInfo(params: GetConnectorInfoRequest): Observable<GetConnectorInfoResponse> {
        return  this.http.get<GetConnectorInfoResponse>(
            UrlPath.STATION_TEST_URL + 'web/connector-info',
            {
                headers: this.authServices.headers,
                params: {
                    ['stationId']: params.stationId.toString(),
                    ['connectorId']: params.connectorId.toString()
                }
            })
    }

}

export interface  GetConnectorInfoResponse {
    success: boolean,
    state: number,
    type: string,
    price: number,
    priceMin: number,
    amperage: number,
    maxPower: number,
    maxVoltage: number,
    minVoltage: number,
    numOfModule: number,
    maxPowerFull: number,
    numOfModuleFull: number,
    amperageFull: number,
    isFast: number
}

export interface GetConnectorInfoRequest {
    stationId: number,
    connectorId: number
}

export interface SetConnectorPriceRequest {
    stationId: number,
    connectorId: number,
    price: number,
    priceMin: number
}

export interface SetConnectorChargeParamsRequest {
    stationId: number,
    connectorId: number,
    amperage: number,
    maxVoltage: number,
    minVoltage: number,
    maxPower: number,
    numOfModule: number,
    maxPowerFull: number,
    numOfModuleFull: number,
    amperageFull: number
}

export interface SetConnectorChargeParamsResponse {
    success: boolean
}

export interface SetModeRequest {
    stationId: number,
    mode: number
}

export interface SetModeResponse {
    success: boolean
}

export interface GetChargeStatisticRequest {
    dateBegin: string
    dateEnd: string
    stations?: Array<number>
    login?: string
    id?: number
    company?: string
    connectorType?: string
    smth?: string
}

export interface GetChargingStatisticResponse {
    success: boolean
    powerArray: number
    amperageArray: number
    voltageArray: number
    timeArray: number
    socArray: number
}

export interface GetChargingStatisticRequest {
    stationId: number,
    connectorId: number
}

export interface GetChargeReportResponse {
    success: boolean,
    url: string
}

export class NewStation {
    name: string;
    ip: string;
    port: number;
    country: string;
    city: string;
    street: string;
    mapUrl: string;
    price: number;
    priceMin: number;
    latitude: number;
    longitude: number;
    power: number;
    protocol: string;
    serial: string;
    currency: string;
    images: string[];
    utc: number;
    type: string;
    company: string;
}

export class Station {
    id: number
    city: string
    country: string
    street: string
    mode: number
    price: number
    priceMin: number
    latitude: number
    longitude: number
    state: number
    powerAC: number
    powerDC: number
    name: string
    currency: string
    images: StationImage[]
    connectors: Port[]
    is_ac: number
    is_dc: number
    type: string
    withCable: number
    company: string
    firmware: string
    firmware_monitor: string
    utc: number
    serial: string
    protocol: string
    port: number
    ip: string
    visible: number
    canControl: number
    last_online : string
}

export class StationImage {
    id: number;
    url: string;
}

export class Port {
    id: number;
    state: number;
    power: number;
    stationId: number;
    amperage: number;
    type: string;
    connectorId: number;
    imgUrl: string;
    infoText: string;
    meterState: number;
}

export class CurrentPortState {
    portId: number
    connectorId: number
    state: number
    timeLeft: number
    power: number
    amperage: number
    voltage: number
    energyLeft: number
    energy_limit: number
    time_limit: number
    login: string
    soc: number
    type: string
    powerArray: any
    amperageArray: any
    voltageArray: any
    isStartCharging: boolean
}

export class ConfigurationResponse {
    success: boolean
    configuration: Configuration[]
}

export class Configuration {
    key: string
    value: string
}

export class GetLogsResponse {
    success: boolean
    logs: Log[]
}

export class Log {
    time: string
    file_name: string
}

export class RemoveLogRequest {
    station_id: number
    time: string
}

export class ChangeConfigurationRequest {
    stationId: number
    key: string
    value: string
}

export class ChangeConfigurationResponse {
    success: boolean
}

export class StationsResponse {
    error: number
    stations: Station[]
    success: boolean
}

export class StationResponse {
    error: number
    station: Station
    success: boolean
}

export class StationImagesResponse {
    error: number
    images: StationImage[]
    success: boolean
}



export class RebootStationRequest {
    StationId: number
    Type: string
}

export class PortParametersRequest {
    StationId: number
    ConnectorId: number
    Amperage: number
}

export class StartChargingRequest {
    StationId: number
    ConnectorId: number
    TimeLimit?: number
    EnergyLimit?: number
}

export class Answer {
    success: boolean
}
