import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Station, StationsInfoService} from 'app/shared/services/stations-info.service';
import {MatPaginator} from '@angular/material/paginator';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {AllUser, AuthService} from '../../../shared/auth/auth.service';
import * as Chartist from 'chartist';
import {Chart} from '../main-page/main-page.component'
import {MatDialog} from '@angular/material/dialog';
import {StatisticsDialogComponent} from '../../../components/dialogs/statistics-dialog/statistics-dialog.component';
import {number} from 'ng2-validation/dist/number';

declare var require: any
const data: any = require('../../../shared/data/chartist.json')


const MESSAGE = 'Данные не были выбраны!';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    paginationArray: Station[] = [];
    stationsId: Array<number> = new Array<number>();
    filterProperties: any;
    stations: Array<Station>;
    allStations: Array<Station>;
    DEFAULT_URL_IMG = 'assets/img/gallery/station_image.PNG';
    displayedColumns: string[] = ['address', 'connectorType', 'begin', 'end', 'time_left', 'energy_left', 'price', 'currency'];
    dataSource;
    chooseStatios = false;
    isAccept = false;
    isExcel = false;
    totalTime: string;
    totalPrice: string;
    totalEnergy: string;
    count: string;
    url: string;
    idReport: number
    usersName: AllUser[] = []
    showListUser = false
    dialogRef
    @ViewChild('loginInput', {static: false}) loginInput: ElementRef<HTMLInputElement>
    @ViewChild('numberInput', {static: false}) numberInput: ElementRef<HTMLInputElement>
    @ViewChild('companyInput', {static: false}) companyInput: ElementRef<HTMLInputElement>
    @ViewChild('connectorTypeInput', {static: false}) connectorTypeInput: ElementRef<HTMLInputElement>
    @ViewChild('smthInput', {static: false}) smthInput: ElementRef<HTMLInputElement>
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
    @ViewChild('datepicker', {static: true}) datepicker: NgbInputDatepicker
    @ViewChild('menu', {static: false}) menuRef: ElementRef<HTMLElement>
    @ViewChild('outClick', {static: false}) outRef: ElementRef<HTMLElement>
    @ViewChild('chooseCharging', {static: false}) chooseChargingRef: ElementRef<HTMLDivElement>
    @ViewChild('iconSortDown', {static: false}) iconSortDownRef: ElementRef<HTMLDivElement>

    isAdmin = 'oper'

    WidgetlineChart: Chart = {
        type: 'Line', data: data['WidgetlineChart'],
        options: {
            axisX: {
                showGrid: true,
                showLabel: false,
                offset: 0,
            },
            axisY: {
                showGrid: false,
                low: 40,
                showLabel: false,
                offset: 0,
            },
            lineSmooth: Chartist.Interpolation.cardinal({tension: 0}),
            fullWidth: true,
        },
    }

    constructor(
        private toastrService: ToastrService,
        private servicesStation: StationsInfoService,
        private authService: AuthService,
        private calendar: NgbCalendar,
        private rendere2: Renderer2,
        public formatter: NgbDateParserFormatter,
        private dialog: MatDialog) {
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getToday();
    }

    ngOnInit() {
        this.isAdmin = window.localStorage.getItem('Role')
        this.servicesStation.getStations()
            .subscribe(response => {
                this.allStations = response
            })
        this.authService.getAllUsers()
            .subscribe(response => {
                if (response.success) {
                    this.usersName = response.users
                }
            })

        const dateBegin = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
        const dateEnd = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;
        this.servicesStation.getChargings({dateBegin, dateEnd})
            .subscribe(data => {
                this.totalTime = ((parseFloat(data['total_time']) / 60) / 60).toFixed(0)
                this.totalPrice = parseFloat(data['total_price']).toFixed(2)
                this.totalEnergy = parseFloat(data['total_energy']).toFixed(2)
                this.count = data['count']
                this.url = data['url']
                this.dataSource = data['charging_sessions']
            })
    }

    showUserListName() {
        this.showListUser = !this.showListUser
        this.changeBorderColor(this.showListUser)
        this.createBackground(this.usersName)
    }

    setUserName(name: string) {
        this.loginInput.nativeElement.value = name
        this.showListUser = false
        this.changeBorderColor(this.showListUser)
    }

    showSimilarNames(value: string, key: string) {
        const filterNames = this.usersName.filter(name => name.login.includes(value))
        if (!filterNames.length || key === 'Backspace') {
            const div = this.rendere2.selectRootElement('.bg')
            if (div) {
                this.rendere2.removeChild(document.body, div)
            }
            return
        }
        this.createBackground(filterNames)
    }

    removeAllBgDivs() {
        const divs = document.getElementsByClassName('bg')
        for (const value of divs as any) {
            this.rendere2.removeChild(document.body, value)
        }
    }

    createBackground(users: AllUser[]) {
        const rect = this.chooseChargingRef.nativeElement.getBoundingClientRect() as ClientRect
        const div = this.rendere2.createElement('div')
        this.rendere2.addClass(div, 'bg')
        this.rendere2.listen(div, 'click', () => this.removeAllBgDivs())
        const listDivUserName = this.rendere2.createElement('div')
        this.rendere2.setStyle(listDivUserName, 'position', 'fixed')
        this.rendere2.setStyle(listDivUserName, 'top', `${rect.top + rect.height}px`)
        this.rendere2.setStyle(listDivUserName, 'left', `${rect.left}px`)
        this.rendere2.setStyle(listDivUserName, 'background', '#3c4a50')
        this.rendere2.setStyle(listDivUserName, 'display', 'flex')
        this.rendere2.setStyle(listDivUserName, 'flex-direction', 'column')
        this.rendere2.setStyle(listDivUserName, 'height', '100px')
        this.rendere2.setStyle(listDivUserName, 'width', `${rect.width}px`)
        this.rendere2.setStyle(listDivUserName, 'overflow-y', 'scroll')
        this.rendere2.setStyle(listDivUserName, 'overflow-x', 'hidden')
        this.rendere2.setStyle(listDivUserName, 'border-radius', '5px')
        users.forEach(user => {
            const button = this.rendere2.createElement('button')
            this.rendere2.addClass(button, 'button')
            this.rendere2.setProperty(button, 'textContent', user.login)
            this.rendere2.listen(button, 'click', () => {
                this.setUserName(user.login)
            })
            this.rendere2.appendChild(listDivUserName, button)
        })
        this.rendere2.appendChild(div, listDivUserName)
        this.rendere2.appendChild(document.body, div)
    }

    changeBorderColor(focus: boolean) {
        if (focus) {
            this.rendere2.setStyle(this.chooseChargingRef.nativeElement, 'borderColor', '#1dbaef')
            this.rendere2.setStyle(this.iconSortDownRef.nativeElement, 'color', '#1dbaef')
        } else {
            this.rendere2.setStyle(this.chooseChargingRef.nativeElement, 'borderColor', '#bdbdc7')
            this.rendere2.setStyle(this.iconSortDownRef.nativeElement, 'color', '#bdbdc7')
        }
    }

    printCheck() {
        this.toastrService.info('Получение чека...')
        this.servicesStation.getChargeReport(`${this.idReport}`)
            .subscribe(response => {
                if (response.success) {
                    window.open(response.url, '_blank');
                } else {
                    this.toastrService.warning('Не удалось получить чек.')
                }
            })
    }

    showContextMenu(event: MouseEvent, id: number) {
        event.preventDefault()
        this.idReport = id
        this.rendere2.setStyle(this.menuRef.nativeElement, 'top', `${event.layerY}px`)
        this.rendere2.setStyle(this.menuRef.nativeElement, 'left', `${event.layerX}px`)
        this.rendere2.addClass(this.menuRef.nativeElement, 'show')
        this.rendere2.setStyle(this.outRef.nativeElement, 'display', 'block')
    }

    hideContextMenu() {
        this.rendere2.removeClass(this.menuRef.nativeElement, 'show')
        this.rendere2.setStyle(this.outRef.nativeElement, 'display', 'none')
    }


    onChangePage(pageOfItems: Array<any>) {
        this.paginationArray = pageOfItems;
    }

    onChangePagePanel(pageOfItems: Array<any>) {
        this.paginationArray = pageOfItems;
    }

    SelectedStation($event, idStation: number, element, success) {
        if (this.checkSameElement(idStation) || this.stationsId.length === 0) {
            this.stationsId.push(idStation);
            element.classList.add('my_border')
            success.classList.add('success_img');
        } else {
            this.stationsId = this.stationsId.filter((_, index) => {
                return this.stationsId.indexOf(idStation) !== index;
            });
            element.classList.remove('my_border');
            success.classList.remove('success_img');
        }
        if (this.stationsId.length === 0) {
            this.isAccept = true;
            this.isExcel = true;
        } else {
            this.isAccept = false;
            this.isExcel = false;
        }
    }

    checkSameElement(stationId: number): boolean {
        for (const key in this.stationsId) {
            if (this.stationsId[key] === stationId) {
                return false;
            }
        }
        return true;
    }

    ResetAllValues() {
        this.stationsId.length = 0;
        let elems = document.querySelectorAll('.my_border');
        [].forEach.call(elems, function (el) {
            el.classList.remove('my_border');
        });
        elems = document.querySelectorAll('.success_img');
        [].forEach.call(elems, function (el) {
            el.classList.remove('success_img');
        });
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate) {
            // this.isAccept = false;
            this.toDate = date;
            this.datepicker.close();
        } else {
            // this.isAccept = false;
            this.toDate = null;
            this.fromDate = date;
            this.datepicker.close();
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate, input: string): NgbDate {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    OpenDialogContextChooseStations() {
        if (!this.chooseStatios) {
            this.chooseStatios = true;
            console.log(this.stationsId);
        } else {
            this.chooseStatios = false;
            this.isAccept = true;
            this.isExcel = true;
        }
    }

    openDialog(id: number) {
        this.dialogRef = this.dialog.open(
            StatisticsDialogComponent, {
                data: { id },
                width: '500px'
            }
        )
    }

    Accept() {
        if (this.fromDate && this.toDate) {
            this.isAccept = true
            this.chooseStatios = false

            console.log(this.smthInput.nativeElement.value)

            const dateBegin = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
            const dateEnd = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
            this.servicesStation.getChargings({
                dateBegin,
                dateEnd,
                stations: this.stationsId,
                login: this.loginInput.nativeElement.value,
                id: Number(this.numberInput.nativeElement.value),
                company : this.companyInput.nativeElement.value,
                connectorType : this.connectorTypeInput.nativeElement.value,
                smth : this.smthInput.nativeElement.value,
            })
                .subscribe(data => {
                    this.totalTime = ((parseFloat(data['total_time']) / 60) / 60).toFixed(0)
                    this.totalPrice = data['total_price']
                    this.totalEnergy = parseFloat(data['total_energy']).toFixed(2)
                    this.count = data['count']
                    this.url = data['url']
                    this.dataSource = data['charging_sessions']
                    this.isAccept = false
                }, (error) => {
                    this.toastrService.error(error)
                })
        } else {
            this.toastrService.error(MESSAGE)
        }
    }


    Excel() {
        if (this.fromDate && this.toDate) {
            this.isExcel = true
            this.chooseStatios = false

            console.log(this.smthInput.nativeElement.value)

            const dateBegin = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
            const dateEnd = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
            this.servicesStation.getReport({
                dateBegin,
                dateEnd,
                stations: this.stationsId,
                login: this.loginInput.nativeElement.value,
                id: Number(this.numberInput.nativeElement.value),
                company : this.companyInput.nativeElement.value,
                connectorType : this.connectorTypeInput.nativeElement.value,
                smth : this.smthInput.nativeElement.value,
            })
                .subscribe(data => {
                    this.isExcel = false
                    window.open(data['url']);
                }, (error) => {
                    this.toastrService.error(error)
                })
        } else {
            this.toastrService.error(MESSAGE)
        }
    }
}
