import {Component, ElementRef, EventEmitter, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    StationsInfoService,
    Station,
    CurrentPortState,
    ConfigurationResponse,
    Log,
    ChangeConfigurationRequest,
    GetChargingStatisticResponse,
    SetConnectorChargeParamsRequest,
    SetConnectorPriceRequest
} from 'app/shared/services/stations-info.service';
import * as chartsData from '../../../shared/data/chartjs';
import {AmperageData} from '../../../models/AmperageData'
import {GetStationInfoServices} from 'app/shared/services/get-data-from-other-component.service';
import {WorkWithPortService} from 'app/shared/services/work-with-potr.service';
import {trigger, style, transition, animate} from '@angular/animations';
import {ToastrService} from 'ngx-toastr';
import {IGetChargingStatistic, IShowParameterPort} from '../connector/connector.component';
import {FormControl, FormGroup} from '@angular/forms';
import {EditStationComponent} from '../edit-station/edit-station.component';


@Component({
    selector: 'app-station-pages',
    templateUrl: './station-pages.component.html',
    styleUrls: ['./station-pages.component.scss'],
    providers: [WorkWithPortService],
    animations: [
        trigger('param', [
                transition(':enter', [
                    style({opacity: 0}),
                    animate('500ms ease-in')
                ]),
                transition(':leave', [
                    style({opacity: 1}),
                    animate(500, style({opacity: 0}))
                ])
            ]
        ),
        trigger('logs', [
            transition(':enter', [
                style({opacity: 0}),
                animate('500ms ease-in')
            ]),
            transition(':leave', [
                style({opacity: 1}),
                animate(500, style({opacity: 0}))
            ])
        ])
    ]
})
export class StationPagesComponent implements OnInit {

    stationId: number
    address: string
    station: Station
    connectors: CurrentPortState[] = []
    isActive = true
    threeChartLabels = chartsData.areaChartLabels
    secondAmperageData: AmperageData = {series: new Array<any>(), labels: new Array<any>()}
    threeAmperageData: AmperageData = {series: new Array<any>(), labels: new Array<any>()}
    @ViewChild('imageLoading', {static: false}) imageLoadingRef: ElementRef<HTMLElement>
    @ViewChild('fileInput', {static: false}) fileInputRef: ElementRef<HTMLInputElement>

    @ViewChild('editStation', { static: false }) editStation: EditStationComponent

    config: ConfigurationResponse;
    logs: Log[];
    visibilityParam = false
    visabilityLog = false
    visabilityUpdates = false
    visabilityImageLoading = false
    loading = false
    isAdmin = 'oper'
    images: string[] = []
    isShowProcessCharging = false
    options = chartsData.connectorOptionsLineChart
    colors = chartsData.areaChartColors
    type = chartsData.areaChartType
    labels = chartsData.areaChartLabels
    connectorInfo: IGetChargingStatistic
    charging = false
    controllerId = 1
    isShowParameterPort = false
    connectorId = 0
    formParameters = new FormGroup({
        minVoltage: new FormControl(),
        maxVoltage: new FormControl(),
        amperage: new FormControl(),
        numOfModule: new FormControl(),
        maxPower: new FormControl(),
        maxPowerFull: new FormControl(),
        numOfModuleFull: new FormControl(),
        amperageFull: new FormControl(),
        isFast: new FormControl()
    })
    formPricePort = new FormGroup({
        price: new FormControl(),
        priceForMin: new FormControl(),
    })
    arrayPortType = ['chademo', 'ccs', 'gbt']
    showParam = false

    constructor(private activateRoute: ActivatedRoute,
                private statisticsService: StationsInfoService,
                private sendValueServices: GetStationInfoServices,
                public portServices: WorkWithPortService,
                private renderer2: Renderer2,
                private toastr: ToastrService) {
        this.stationId = +activateRoute.snapshot.params['id'];
    }

    ngOnInit() {
        this.isAdmin = window.localStorage.getItem('Role')
        this.statisticsService.getStationInfo(this.stationId).subscribe(response => {
            this.sendValueServices.getValue(response.station)
            this.station = response.station
            console.log(response)
            this.address = this.station.city + ',' + this.station.street
            //this.station.images.forEach(image => this.images.push(image.url))
        })
    }

    setModeStation(stationId: number, mode: number) {
        this.statisticsService.setMode({stationId, mode})
            .subscribe(response => {
                if (response) {
                    this.toastr.success('Режим работы станции изменен успешно!')
                } else {
                    this.toastr.warning('Режим работы станции не удалось изменить! Попробуйте в другой раз.')
                }
            })
    }

    panController(event: Event) {
        this.controllerId = +(event.target as HTMLInputElement).value
    }


    getParametersPort(data: IShowParameterPort) {
        this.statisticsService.getConnectorInfo({
            stationId: this.stationId,
            connectorId: data.connectorId
        })
            .subscribe(response => {
                console.log('getConnectorInfo', response)
                if (response.success) {
                    this.formPricePort.setValue({
                        price: response.price || 0,
                        priceForMin: response.priceMin || 0
                    })
                    this.formParameters.setValue({
                        minVoltage: response.minVoltage || 0,
                        maxVoltage: response.maxVoltage || 0,
                        amperage: response.amperage || 0,
                        numOfModule: response.numOfModule || 0,
                        maxPower: response.maxPower || 0,
                        maxPowerFull: response.maxPowerFull || 0,
                        numOfModuleFull: response.numOfModuleFull || 0,
                        amperageFull: response.amperageFull || 0,
                        isFast: response.isFast || 0
                    })
                    this.isShowParameterPort = data.isShow
                    this.connectorId = data.connectorId
                    if (this.arrayPortType.includes(response.type.toLowerCase())) {
                        this.showParam = true
                    } else {
                        this.showParam = false
                    }
                }
            })
    }

    setPricePort() {
        const body: SetConnectorPriceRequest = {
            price: +this.formPricePort.get('price').value,
            priceMin: +this.formPricePort.get('priceForMin').value,
            connectorId: this.connectorId,
            stationId: this.stationId
        }
        console.log(body)
        this.statisticsService.setConnectorPrice(body)
            .subscribe(response => {
                if (response) {
                    this.toastr.success('Цена для коннектора успешно установлена!')
                } else {
                    this.toastr.success('Ошибка сервера!')
                }
            })
    }

    setParametersPort() {
        const body: SetConnectorChargeParamsRequest = {
            maxPower: +this.formParameters.get('maxPower').value,
            amperage: +this.formParameters.get('amperage').value,
            maxVoltage: +this.formParameters.get('maxVoltage').value,
            minVoltage: +this.formParameters.get('minVoltage').value,
            numOfModule: +this.formParameters.get('numOfModule').value,
            maxPowerFull: +this.formParameters.get('maxPowerFull').value,
            numOfModuleFull: +this.formParameters.get('numOfModuleFull').value,
            amperageFull: +this.formParameters.get('amperageFull').value,
            connectorId: this.connectorId,
            stationId: this.stationId
        }
        console.log(body)
        this.statisticsService.setConnectorChargeParams(body)
            .subscribe(response => {
                if (response) {
                    this.toastr.success('Параметры для коннектора успешно установлены!')
                } else {
                    this.toastr.success('Ошибка сервера!')
                }
            })
    }

    closeParameterModalWindow() {
        this.isShowParameterPort = false
    }

    getShowPrecessResponse(response: IGetChargingStatistic) {
        this.charging = response.isShow
        this.connectorInfo = response
    }

    closeProcessCharging() {
        console.log("11111")
        this.charging = false

        if (this.editStation.isOpen) {
            this.editStation.toggleEditor()
        }
    }

    uploadImageOnServer() {
        if (this.images.length) {
            this.statisticsService.changeStationImages(this.station.id, this.images)
                .subscribe(isLoad => {
                    if (isLoad) {
                        this.toastr.success('Изображения сохранены')
                    } else {
                        this.toastr.warning('Не удалось сохранить изображения')
                    }
                })
        }
    }

    loadImages() {
        this.fileInputRef.nativeElement.click()
    }

    selectedFiles(files: FileList) {
        const reader = new FileReader()
        reader.onload = (event: ProgressEvent) => {
            this.images.length = 0
            this.images.push(event.target['result'])
        }
        reader.readAsDataURL(files.item(0));
    }

    deleteImage(id: number) {
        this.images = this.images.filter((_, index) => index !== id)
    }

    closeImageLoader() {
        this.renderer2.setStyle(this.imageLoadingRef.nativeElement, 'visibility', 'hidden')
    }

    RebootStation(StationId: number, Type: string) {
        this.statisticsService.rebootStation({StationId, Type});
    }

    animate() {
        this.loading = true
        if (this.visabilityLog) {
            this.visabilityLog = false
        }

        if (this.visabilityUpdates) {
            this.visabilityUpdates = false
        }

        if (this.visabilityImageLoading) {
            this.visabilityImageLoading = false
        }

        this.visibilityParam = !this.visibilityParam
        if (this.visibilityParam) {
            this.config = null
            this.statisticsService.getConfiguration(this.stationId)
                .subscribe(response => {
                    if (response.success) {
                        this.loading = false
                        this.config = response
                        this.toastr.success('Конфигурации успешно получены')
                    } else {
                        this.loading = false
                        this.visibilityParam = false
                        this.toastr.warning('Ошибка получения конфигураций')
                    }
                })
        }
    }

    requestLogs() {
        this.statisticsService.requestLogs(this.stationId)
            .subscribe(response => {
                if (response) {
                    this.toastr.success('Логи успешно загружены на сервер')
                } else {
                    this.toastr.warning('Ошибка загрузки логов на сервер')
                }
            })
    }

    openSettings() {
        this.editStation.open()
        event.stopPropagation();
    }



    ping() {
        this.statisticsService.ping(this.stationId)
            .subscribe(response => {
                if (response) {
                    this.toastr.success('Пинг успешен')
                } else {
                    this.toastr.warning('Пинг не работает')
                }
            })
    }

    pingSimCard() {
        this.statisticsService.pingSimCard(this.stationId)
            .subscribe(response => {
                if (response) {
                    this.toastr.success('Пинг успешен')
                } else {
                    this.toastr.warning('Пинг не работает')
                }
            })
    }

    getLogs() {
        this.loading = true
        if (this.visibilityParam === true) {
            this.visibilityParam = false
        }
        this.visabilityLog = !this.visabilityLog
        if (this.visabilityLog) {
            this.statisticsService.getLogs(this.stationId)
                .subscribe(response => {
                    if (response.success) {
                        this.toastr.success('Логи успешно получены')
                        this.logs = response.logs
                    } else {
                        this.toastr.warning('Ошибка получение логов')
                    }
                    this.loading = false
                })
        }
    }

    getUpdates() {
        this.visabilityUpdates = true
    }

    removeLog(time: string) {
        this.statisticsService.removeLog({station_id: this.stationId, time})
            .subscribe(resp => {
                if (resp['success']) {
                    this.toastr.success('Логирование успешно удалено')
                    this.statisticsService.getLogs(this.stationId)
                        .subscribe(response => {
                            if (response.success) {
                                this.toastr.success('Логи успешно обновлены')
                                this.logs = response.logs
                            } else {
                                this.toastr.warning('Ошибка обновления логов')
                            }
                        })
                } else {
                    this.toastr.warning('Ошибка удаление логирования')
                }
            })
    }

    hide() {
        if (this.visabilityLog) {
            this.visabilityLog = false
        }
        if (this.visibilityParam) {
            this.visibilityParam = false
        }
        if (this.visabilityUpdates) {
            this.visabilityUpdates = false
        }

        if (this.visabilityImageLoading) {
            this.visabilityImageLoading = false
        }

       // if (this.isShowParameterPort) {
            this.isShowParameterPort = false
       // }

    }

    save(key: string, value: string, index: number) {
        let element: HTMLLIElement;
        if (value === 'true' || value === 'false') {
            element = document
                .getElementsByClassName(`selected_style ${index}`).item(0) as HTMLLIElement
        } else {
            element = document
                .getElementsByClassName(`input_style ${index}`).item(0) as HTMLLIElement
        }
        if (value === element.value.toString()) {
            this.toastr.warning('Поменяйте значение конфигурации')
        } else {
            const body: ChangeConfigurationRequest = {
                stationId: this.stationId,
                key,
                value: element.value.toString()
            }
            this.statisticsService.changeConfiguration(body)
                .subscribe(resp => {
                    if (resp.success) {
                        this.loading = true
                        this.config = null
                        this.statisticsService.getConfiguration(this.stationId)
                            .subscribe(response => {
                                if (response.success) {
                                    this.config = response
                                    this.toastr.success('Конфигурации успешно получены')
                                } else {
                                    this.toastr.warning('Ошибка получения конфигураций')
                                }
                                this.loading = false
                            })
                    }
                })
        }
    }

    checkElement(value: string): boolean {
        if (value === 'true' || value === 'false') {
            return false
        }
        return true
    }


    /*
    loadStationImage(e) {
        const reader = new FileReader();
        reader.onload = (event: Event) => {
            this.imgArray.push(event.target['result']);
        }
        reader.readAsDataURL(e.srcElement.files[0]);
    }

    removeImg(src: string) {
        this.imgArray = this.imgArray.filter(img => img !== src)
    }*/

    showLoadingImage() {
        this.visabilityImageLoading = true;
        console.log('1234');
        this.statisticsService.getStationImages(this.stationId).subscribe(response => {
            console.log(response.images)
            this.images.length = 0
            if (response.images != null) {
                response.images.forEach(image => this.images.push(image.url))
            }
            console.log(this.images)
            this.renderer2.setStyle(this.imageLoadingRef.nativeElement, 'visibility', 'visible')
        })
    }
}
