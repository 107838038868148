import { FormControl } from '@angular/forms'

export class CustomValidators {

    static forbiddenSymbols(control: FormControl): { isSymbols: boolean, array: string[] } {
        const symbols: string[] = ['*', '/', '|', '~', '^', '$', '#', '@', '!', '&', '$', '№', '?', '%']
        let str = <string>(control.value)
        for (let i = 0; i < str.length; i++) {
            if (symbols.includes(str[i])) {
                return { isSymbols: true, array: symbols }
            }
        }
        return null
    }

    static trimValidator(control: FormControl): { [key: string]: boolean } {
        const str = control.value != null ? (<string>control.value).trim() : null
        if (!str) {
            return { trim: true }
        }
        return null
    }

    static isNumberValue(control: FormControl): { [key: string]: boolean } {
        if (isNaN(parseFloat(control.value))) {
            return { isText: true }
        }
        return null
    }

}