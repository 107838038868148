import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'
import {AllUser, AuthService} from 'app/shared/auth/auth.service';
import { Station, StationsInfoService } from 'app/shared/services/stations-info.service';
import { SaveUserStationsRequest, StatisticsService, GetTagsResponse, NewTagTagRequest } from 'app/shared/services/statistics.service';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette, MatAutocompleteSelectedEvent } from '@angular/material'
import {User} from '../../../models/auth/User';


interface UserBody {
  login: string,
  password: string,
  email: string,
  role: string,
  company: string
}

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent implements OnInit {
  role: string;
  myForm: FormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
    login: new FormControl(),
    role: new FormControl(),
    company: new FormControl(),
  });

  formStations: FormGroup = new FormGroup({
    users: new FormControl(),
  })

  formStationFree: FormGroup = new FormGroup({
    user: new FormControl(),
    street: new FormControl()
  });

  rfidForm: FormGroup = new FormGroup({
    rfid: new FormControl(),
    user: new FormControl(),
  });

  users: User[]
  allUsers: AllUser[]
  userId: number
  stations: Station[]
  toppings = new FormControl()
  selectUser = ''
  statinsId: Array<number> = new Array<number>()
  rfids: GetTagsResponse

  @ViewChild('rfidUser', {static: false}) usetRfInput: ElementRef;

  @Output()
  selectionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  optionSelected: EventEmitter<MatAutocompleteSelectedEvent> = new EventEmitter<MatAutocompleteSelectedEvent>();

  @Input()
  color: ThemePalette;


  constructor(private authService: AuthService,
    private stationsServices: StationsInfoService,
    private statisticService: StatisticsService,
    private toasts: ToastrService) {
      this.rfids = new GetTagsResponse()
      this.rfids.idTags = Array<NewTagTagRequest>()
    }

  ngOnInit() {
    console.log('11111')
    console.log(this.toppings.value)
    this.authService.getAllUsers()
      .subscribe(response => {
        if (response.success) {
          this.allUsers = response.users

          this.allUsers.sort(function (a, b) {
            if (a.login > b.login) {
              return 1;
            }
            if (a.login < b.login) {
              return -1;
            }
            return 0;
          });

        }
      })
    this.authService.getUsers().subscribe(response => this.users = response)

    this.stationsServices.getStations()
      .subscribe(_stations => {
        this.stations = _stations;
      },
        error => console.log(error));


    this.statisticService.getAllRfid()
      .subscribe(data => {
        if (data.success) {
          this.rfids = data != null ? data : new GetTagsResponse()
        }
      },
        error => { console.log(error) })

    this.role = localStorage.getItem('Role');

  }

  createNewUser() {
    const body: UserBody = {
      login: this.myForm.get('login').value,
      password: this.myForm.get('password').value,
      email: this.myForm.get('email').value,
      role: this.myForm.get('role').value,
      company: this.myForm.get('company').value
    }

    if (body.login != null && body.password != null && body.role != null) {
      body.email = body.login + '@eservice.bb'
      this.authService.addNewUser(body)
        .subscribe(data => {
          if (data['success']) {
            this.authService.getUsers()
              .subscribe(response => {
                this.users = response
                this.toasts.success('Пользователь был успешно добавлен!')
              },
                error => console.log(error)
              );
          } else {
            this.toasts.error('Повторите ввод данных позже!", "Ошибка сервева');
          }
        },
          error => console.log(error))
    } else {
      this.toasts.error('Данные были некорректно введены!')
    }
  }

  provideFreeAccess() {
    const userName = this.formStationFree.get('user').value
    const streets = this.formStationFree.get('street').value as string[]
    const userId = this.allUsers.find(u => u.login === userName).id
    const stations = this.stations
        .filter(s => streets.includes(s.street))
        .map(s => s.id)
    const body = {
      userId,
      stations
    }
    this.authService.saveUserFreeAccess({user_id : userId, stations})
        .subscribe(response => {
          if (response.success) {
            this.toasts.success('Данные были успешно сохранены!')
          }
        }, error => {
          this.toasts.error('Ошибка соединения с сервером!')
        })
  }

  save() {
    if (this.userId != null) {
      this.statinsId.length = 0
      Object.entries(this.toppings.value).forEach(
        street => {
          this.stations.forEach(station => {
            if (street.includes(station.street)) {
              this.statinsId.push(station.id);
            }
          });
        });

      const body: SaveUserStationsRequest = {
        user_id: this.userId,
        stations: this.statinsId
      }

      this.statisticService.saveStationsForUser(body)
        .subscribe(data => {
          if (data['success']) {
            this.toasts.success('Данные успешно сохранены!')
            this.stationsServices.getStations()
              .subscribe(_stations => {
                this.stations = _stations
              },
                error => console.log(error))
          } else  {
            this.toasts.error('Ошибка сохранения данных!')
          }
        },
          error => console.log(error))
    } else {
      this.toasts.error('Выберете пожалуйста пользователя!')
    }
  }

  userSelectedFreeStation(name: string) {
    const userId = this.allUsers.find(u => u.login === name).id
    this.authService.userFreeAccess(`${userId}`)
        .subscribe(response => {
          console.log(response)
          if (response.success && response.stations != null) {
            this.formStationFree.setValue({
              user: name,
              street: response.stations.map(s => s.street)
            })
          } else {
            this.formStationFree.setValue({ user: name, street: [] })
          }
        },
            error => {
              this.toasts.error('Ошибка соединения с сервером!')
        })
  }

  userSelected(_user: string) {
    this.userId = this.users.find(user => user.login === _user).id;
    this.getAllUserStations();
  }

  getAllUserStations() {
    this.toppings.setValue(null)
    this.statisticService.getAllUserStations(this.userId.toString())
      .subscribe(data => {
        if (data.success && data.stations != null) {
          this.toppings.setValue(data.stations.map(station => station.street));
        }
      },
        error => console.log(error));
  }

  rfid() {
    const body: NewTagTagRequest = {
      idTag: this.rfidForm.get('rfid').value,
      login: this.rfidForm.get('user').value
    }
    this.statisticService.createNewRfid(body)
      .subscribe(data => {
        if (data.success) {
          this.statisticService.getAllRfid()
            .subscribe(_data => {
              if (data.success) {
                this.rfids = _data
                console.log(this.rfids)
              }
            },
              error => { console.log(error) })
        }
      })
  }

  selectedRfUser(tagId: string) {
    this.usetRfInput.nativeElement.value = this.rfids.idTags.find(tag => tag.idTag === tagId).login
  }


}
