import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AuthService} from '../auth/auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators'
import {ChartModel} from 'app/models/chartModel';
import {DatePipe} from '@angular/common'
import {UrlPath} from '../auth/urlPathConst';


@Injectable({
    providedIn: 'root'
})
export class StatisticsService {

    private chartLinerData: ChartModel;

    constructor(private authService: AuthService, private http: HttpClient, private datePipe: DatePipe) {
    }

    public getActivStations(): Observable<ChartModel> {
        return this.http.get(UrlPath.SERVICE_STATISTICS + 'api/activeStationsCount', {headers: this.authService.headers})
            .pipe(map(data => {
                const model: ChartModel = {
                    percent: data['percent'],
                    data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding, data['count'], data['activeCount'])}
                }
                return model;
            }));
    }

    /*
    public getLastThirtyChargings(): Observable<ChartModel> {
        return this.http.get(UrlPath.SERVICE_STATISTICS + 'api/chargingCountByDate', {headers: this.authService.headers})
            .pipe(map(data => {
                this.chartLinerData = new ChartModel()
                const labels: string[] = []
                const countArray: number[] = []
                for (let i = 0; i < data['chargings'].length; i++) {
                    countArray[i] = data['chargings'][i]['count']
                    labels[i] = data['chargings'][i]['date']
                }
                this.chartLinerData.data = {labels: labels, series: countArray}
                return this.chartLinerData;
            }));

    }
    */


    public getLastThirtyChargings(stationId: number): Observable<ChartModel> {
        return this.http.get(UrlPath.STATION_TEST_URL + 'web/charging-count-by-date', {
            headers: this.authService.headers,
            params: new HttpParams().set('StationId', String(stationId))
        })
            .pipe(map(data => {
                this.chartLinerData = new ChartModel()
                const labels: string[] = []
                const countArray: number[] = []
                for (let i = 0; i < data['chargings'].length; i++) {
                    countArray[i] = data['chargings'][i]['count']
                    labels[i] = data['chargings'][i]['date']
                }
                this.chartLinerData.data = {labels: labels, series: countArray}
                return this.chartLinerData;
            }));

    }

    public getPortsBusy(): Observable<ChartModel> {
        return this.http.get(UrlPath.SERVICE_STATISTICS + 'api/busyPortsCount', {headers: this.authService.headers})
            .pipe(map(data => {
                const model: ChartModel = {
                    percent: data['percent'],
                    data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding, data['count'], data['activeCount'])}
                }
                return model;
            }));
    }

    public getChargingCount(): Observable<ChartModel[]> {
        return this.http.get(UrlPath.SERVICE_STATISTICS + 'api/chargingCount', {headers: this.authService.headers})
            .pipe(map(data => {
                const models: ChartModel[] = [{
                    percent: data['paidPercent'],
                    data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding, data['count'], data['paidCount'])}
                },
                    {
                        percent: data['nightPercent'],
                        data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding, data['count'], data['nightCount'])}
                    }
                ]
                return models;
            }))
    }

    private getIChartistSeriesData(_firstCssNme: CssName, _secondCssName: CssName, count: number, total: number):
        Chartist.IChartistSeriesData[] {
        const chartSeriesData: Chartist.IChartistSeriesData[] =
            [{className: _firstCssNme, value: count},
                {className: _secondCssName, value: total}];
        return chartSeriesData;
    }

    public getChargingFullStatistics(stationId: number): Observable<any> {
        return this.http.get(UrlPath.STATION_TEST_URL + 'web/statistic', {
            headers: this.authService.headers,
            params: new HttpParams().set('StationId', String(stationId))
        });
    }

    public getCurrentState(): Observable<any> {
        return this.http.get(UrlPath.STATION_TEST_URL + 'web/current-state', {headers: this.authService.headers});
    }

    public getChargingTotalStatistic(stationId: number): Observable<any> {
        return this.http.get(UrlPath.STATION_TEST_URL + 'web/charging-total-statistic', {
            headers: this.authService.headers,
            params: new HttpParams().set('StationId', String(stationId))
        });
    }

    public saveStationsForUser(body: SaveUserStationsRequest): Observable<boolean> {
        return this.http.post<boolean>(UrlPath.SERVICE_STATISTICS + 'web/station-access', body, {headers: this.authService.headers})
    }

    public getAllUserStations(userId: string): Observable<GetStationsForUserResponse> {
        return this.http.get<GetStationsForUserResponse>(UrlPath.SERVICE_STATISTICS + 'web/station-access', {
            headers: this.authService.headers,
            params: new HttpParams().set('UserId', userId)
        })
    }

    public createNewRfid(body: NewTagTagRequest): Observable<NewTagTagResponse> {
        return this.http.post<NewTagTagResponse>(UrlPath.SERVICE_STATISTICS + 'api/saveTag', body, {headers: this.authService.headers})
    }

    public getAllRfid(): Observable<GetTagsResponse> {
        return this.http.get<GetTagsResponse>(UrlPath.SERVICE_STATISTICS + 'api/tags', {headers: this.authService.headers})
    }

    public getTransactions(_body: GetTransactionsRequest): Observable<GetTransactionsResponse> {
        const params = new HttpParams().set('begin', _body.begin).set('end', _body.end).set('login', _body.login).set('company', _body.company)
            .set('state', String(_body.state)).set('acquiring', _body.acquiring)
        return this.http.get<GetTransactionsResponse>(UrlPath.MONEY + 'web/transactions', {
            headers: this.authService.headers,
            params
        })
    }

    public getUserCharge(id: string): Observable<UserChargeResponse> {
        return this.http.get<UserChargeResponse>(
            `${UrlPath.STATION_TEST_URL}web/charging-sessions/${id}`,
            {headers: this.authService.headers})
    }
}

export interface UserChargeResponse {
    success: boolean
    id: number
    address: string
    company: string
    connector_type: string
    connector_id: number
    begin: string
    end: string
    time_left: number
    energy_left: number
    price: number
    currency: string
    stop_reason: string
    tariffication: number
    tarif: number
    max_power: number
    begin_soc: number
    end_soc: number
}

export interface GetTransactionsRequest {
    begin: string
    end: string
    login: string
    company: string
    state: number
    acquiring: string
}

export interface GetTransactionsResponse {
    success: boolean
    transactions: Transaction[]
}

export interface Transaction {
    id: number,
    price: number,
    login: string,
    currency: string,
    acquiring: string,
    company: string,
    state: number,
    time: string
}

export class GetTagsResponse {
    success: boolean;
    idTags: NewTagTagRequest[];
}

export class NewTagTagRequest {
    idTag: string;
    login: string;
}

export class NewTagTagResponse {
    success: boolean;
}

export enum CssName {
    Done = 'ct-done',
    Outstanding = 'ct-outstanding'
}

export class SaveUserStationsRequest {
    user_id: number;
    stations: number[];
}

export class GetStationsForUserResponse {
    success: boolean;
    stations: StationUser[];
}

export class StationUser {
    street: string;
    id: number;
}
