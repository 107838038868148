import {Component, OnInit, OnDestroy, Input, ElementRef, ViewChildren, QueryList, Output, EventEmitter} from '@angular/core'
import {
  CurrentPortState,
  GetChargingStatisticResponse,
  StartChargingRequest,
  StationsInfoService
} from 'app/shared/services/stations-info.service'
import { Subscription, interval } from 'rxjs'
import * as chartsData from '../../../shared/data/chartjs'
import { transition, trigger, style, animate } from '@angular/animations'
import { IndividualConfig, ToastrService } from 'ngx-toastr'


let amperText: string
let powetText: string
let volitText: string
const CONFIG_TOAST: Partial<IndividualConfig> = {
  progressBar: true,
  closeButton: true,
  progressAnimation: 'increasing'
}

export interface IGetChargingStatistic {
  info?: GetChargingStatisticResponse,
  isShow: boolean
}

export interface IShowParameterPort {
  connectorId: number,
  isShow: boolean
}


@Component({
  selector: 'app-connector',
  templateUrl: './connector.component.html',
  styleUrls: ['./connector.component.scss'],
  animations: [trigger('startCharge', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('300ms ease-in')
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(300, style({ opacity: 0 }))
    ])
  ])]
})
export class ConnectorComponent implements OnInit, OnDestroy {

  @Input() stationId: number
  @Input() role: string
  @Input() charging: boolean
  @Output() showPrecessCharging = new EventEmitter<IGetChargingStatistic>()
  @Output() parameterPort = new EventEmitter<IShowParameterPort>()

  @ViewChildren('canvasAmper') canvasAmper: QueryList<ElementRef<HTMLCanvasElement>>
  @ViewChildren('canvasPower') canvasPower: QueryList<ElementRef<HTMLCanvasElement>>
  @ViewChildren('canvasVolt') canvasVolt: QueryList<ElementRef<HTMLCanvasElement>>

  @ViewChildren('time') timeRef: QueryList<ElementRef<HTMLInputElement>>
  @ViewChildren('amperage') amperageRef: QueryList<ElementRef<HTMLInputElement>>
  ctxPower: CanvasRenderingContext2D[] = []
  ctxAmper: CanvasRenderingContext2D[] = []
  ctxVolt: CanvasRenderingContext2D[] = []

  pluginAmper = []
  pluginVolt = []
  pluginPower = []


  connectors: CurrentPortState[]
  sub: Subscription
  isActive: boolean
  visibility = false
  firstData = chartsData.areaChartData
  firstChartLabels = chartsData.areaChartLabels
  firstChartOptions = chartsData.updateChart
  firstChartColors = chartsData.udateChartColors
  firstChartType = chartsData.areaChartType
  firstChartLegend = chartsData.areaChartLegend
  btnProcess = false
  isAdmin = 'oper'
  intervalTimer = interval(10000)
  subscription: Subscription
  constructor(private statServices: StationsInfoService, private toast: ToastrService) { }


  ngOnInit() {
    this.isAdmin = window.localStorage.getItem('Role')
    if (window.localStorage.getItem('Language') !== 'ru') {
      amperText = 'AMPER'
      volitText = 'VOLTAGE'
      powetText = 'POWER'
    } else {
      amperText = 'АМПЕР'
      volitText = 'ВОЛЬТ'
      powetText = 'МОЩНОСТЬ'
    }
    this.statServices.getStationConnectors(this.stationId)
      .subscribe(data => {
        if (data != null) {
          this.connectors = data
        }
      })
    this.sub = interval(30000)
        .subscribe(() => this.getConnectorsInfo())

  }


  ngOnDestroy() {
    this.sub.unsubscribe()
  }


  getConnectorsInfo() {
    this.statServices.getStationConnectors(this.stationId)
      .subscribe(data => {
        if (data != null) {
          data.forEach((item, index) => {
            this.connectors[index].power = item.power || 0
            this.connectors[index].powerArray = item.powerArray
            this.connectors[index].amperageArray = item.amperageArray
            this.connectors[index].voltageArray = item.voltageArray
            this.connectors[index].amperage = item.amperage || 0
            this.connectors[index].voltage = item.voltage || 0
            this.connectors[index].state = item.state
            this.connectors[index].soc = item.soc
            this.connectors[index].energyLeft = item.energyLeft || 0
            this.connectors[index].timeLeft = item.timeLeft || 0
          })
          if (this.canvasPower.length !== 0) {
            this.ctxPower.length = 0
            this.ctxAmper.length = 0
            this.ctxVolt.length = 0
            this.canvasPower.forEach((canvas, index) => {
              this.ctxPower.push(canvas.nativeElement.getContext('2d'))
              this.ctxAmper.push(this.canvasAmper.toArray()[index].nativeElement.getContext('2d'))
              this.ctxVolt.push(this.canvasVolt.toArray()[index].nativeElement.getContext('2d'))
            })
          }

          if (this.ctxPower.length !== 0) {
            this.startDrawInfoCanvas()
          }
        }
      })
  }

  startDrawInfoCanvas() {
    let temp = 0
    this.connectors.forEach((c, i) => {
      if (c.state === 3) {
        this.drawInfoOnCanvas(this.ctxPower[temp], powetText, `${c.power}`, 'P')
        this.drawInfoOnCanvas(this.ctxVolt[temp], volitText, `${c.voltage}`, 'V')
        this.drawInfoOnCanvas(this.ctxAmper[temp], amperText, `${c.amperage}`, 'A')
        temp++
      }
    })
  }


  batteryFillingWarn(percent: number) {
    return percent > 20 && percent <= 30;
  }

  batteryFillingAlert(percent: number) {
    return percent <= 10;
  }

  makeAvailable(connectorId: number) {
    const body = { StationId: this.stationId, ConnectorId: connectorId, State: 254 }
    this.statServices.makeNotAvailablePort(body)
  }

  makeNotAvailable(connectorId: number) {
    const body = { StationId: this.stationId, ConnectorId: connectorId, State: 255 }
    this.statServices.makeNotAvailablePort(body)
  }

  finishCharging(connectorId: number) {
    this.isActive = true
    this.statServices.stopCharging(this.stationId, connectorId)
  }

  startChargingAdmin(ConnectorId: number, index: number) {
    this.connectors[index].isStartCharging = !this.connectors[index].isStartCharging
  }

  drawInfoOnCanvas(ctx: CanvasRenderingContext2D, type: string, value: string, symbol: string) {
    console.log(`type: ${type} value: ${value} symbol: ${symbol}`)
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.font = `25px "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`
    ctx.fillStyle = 'white'
    ctx.fillText(type, 10, 25)
    ctx.font = `30px "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`
    ctx.fillText(value, 75, 105)
    ctx.beginPath()
    ctx.arc(28, 95, 15, 0, 2 * Math.PI, false)
    ctx.strokeStyle = '#ffffff'
    ctx.stroke()
    ctx.font = `25px "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`
    ctx.fillText(symbol, 20, 105)
  }

  startChargingOper(ConnectorId: number, index: number) {
    const length = this.amperageRef.toArray().length
    const EnergyLimit = length !== 1
      ? +this.amperageRef.toArray()[index].nativeElement.value
      : +this.amperageRef.first.nativeElement.value

    const TimeLimit = length !== 1
            ? +this.timeRef.toArray()[index].nativeElement.value
            : +this.timeRef.first.nativeElement.value

    this.startCharging({ StationId: this.stationId, ConnectorId, TimeLimit, EnergyLimit }, index)
  }

  startCharging(body: StartChargingRequest, index: number) {
    this.connectors[index].isStartCharging = false
    this.statServices.startCharging(body)
      .subscribe(response => {
        if (response.success) {
          this.toast.success(
            'Зарядка успешна началась.',
            'Начало зарядки.',
            CONFIG_TOAST
          )
        } else {
          this.toast.warning(
            'Зарядка не может начаться.',
            'Начало зарядки.',
            CONFIG_TOAST
          )
        }
      })
  }

  install(connectorId: number, value: string) {
    const body = { StationId: this.stationId, ConnectorId: connectorId, Amperage: +value }
    this.statServices.setPortParameters(body)
      .subscribe(res => {
        if (res['success']) {
          this.toast.success(
            `По коннектору ${connectorId} установлен апераж на ${body.Amperage}.`,
            'Установка апеража.',
            CONFIG_TOAST)
        } else {
          this.toast.warning(
            `По коннектору ${connectorId} установить апмераж на ${body.Amperage} не удалось.`,
            'Установка апеража.',
            CONFIG_TOAST)
        }
      })
  }

  showParameters(connectorId: number) {
    this.parameterPort.emit({
      isShow: true,
      connectorId
    })
    // if (connector.type !== 'Type2' && connector.type !== 'Type1') {
    //   this.visibility = false
    // } else {
    //   this.visibility = true
    // }
  }

  showChargingProcess(connectorId) {
    this.charging = true
    this.statServices.getChargingStatistic({
      connectorId,
      stationId: this.stationId
    }).subscribe(info => {
      this.showPrecessCharging.emit({
        info,
        isShow: this.charging
      })
    })
  }


}
