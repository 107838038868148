import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  subject$ = new Subject();

  constructor() { }

  changeLanguage(language: string) {
    this.subject$.next(language)
  }

  showFilter(value: boolean) {
    this.subject$.next(value)
  }

}
