import { RouteInfo } from './sidebar.metadata';


export const ROUTES: RouteInfo[] = [
    {
        path: '',
        title: 'mainMenu.generalInfo',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        isAdminMenu: false,
    },
    {
        path: '/station-charact',
        title: 'mainMenu.allStations',
        icon: 'ft-file-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        isAdminMenu: false
    },
    {
        path: '/statistics',
        title: 'mainMenu.sessions',
        icon: 'ft-calendar',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        isAdminMenu: false
    },
    {
        path: '/addNewUser',
        title: 'mainMenu.accessControl',
        icon: 'icon-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        isAdminMenu: true
    },
    {
        path: '/transactions',
        title: 'mainMenu.transactions',
        icon: 'icon-list',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        isAdminMenu: true
    },
    {
        path: '/balance',
        title: 'mainMenu.balance',
        icon: 'icon-wallet',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        isAdminMenu: true
    }
];
