import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'app/shared/validators/customValidators';
import { User } from '../../../models/auth/User';
import { ChangeUserDataRequest } from '../../../models/auth/ChangeUserDataRequest';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  companyFlag = true;
  userFlag = false;
  users: User[];
  currentUser: User;
  name: string;
  role: string;
  imgUrl = 'assets/img/portrait/avatars/avatar-01.png'
  private email: string;

  @ViewChild('loadImg', { static: false }) input: ElementRef;

  form: FormGroup = new FormGroup({
    email: new FormControl(localStorage.getItem('Email'), Validators.email),
    phone: new FormControl(null, CustomValidators.isNumberValue),
    fio: new FormControl(),
  });


  constructor(private router: Router,
    private auth: AuthService,
    private toast: ToastrService) { }


  ngOnInit() {
    this.role = localStorage.getItem('Role')
    this.name = localStorage.getItem('Login')
    this.email = localStorage.getItem('Email')
    this.auth.getUsers()
      .subscribe(response => {
        this.users = response
        this.currentUser = this.users.find(u => u.login === this.name);
        if (this.currentUser) {
          this.imgUrl = this.currentUser.avatarUrl
        }
      })
  }

  changePanel(value: string) {
    if (value === 'user') {
      if (this.userFlag !== true) {
        this.companyFlag = false;
        this.userFlag = true;
      }
    } else if (value === 'company') {
      if (this.companyFlag !== true) {
        this.userFlag = false;
        this.companyFlag = true;
      }
    }
  }

  delete(id: number) {
    const body = {
      userId: id
    }
    this.auth.deleteUser(body)
      .subscribe(data => {
        if (data.success) {
          this.auth.getUsers()
            .subscribe(response => {
              this.users = response
            })
          this.toast.success('Пользователь был успешно удален!')
        } else {
          this.toast.error(data.errorCode.toString())
        }
      })
  }

  submit() {
    if (!this.form.invalid) {
      this.auth.changeUserData(<ChangeUserDataRequest>this.form.value)
        .subscribe(data => {
          if (data.success) {
            this.toast.success('Профиль успешно обновлен!')
          }
        },
        error => this.toast.warning(error))
    }
  }

  load() {
    this.input.nativeElement.click()
    this.input.nativeElement.onchange = () => {
      const reader = new FileReader();
      reader.onload = () => {
        this.imgUrl = reader.result as string;
      }
      reader.readAsDataURL(this.input.nativeElement.files[0])
    }
  }
}
