// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCLjd1Ea386dD84UJL2PF87FWm1m282hio',
    authDomain: 'chargeandgo-c73e1.firebaseapp.com',
    databaseURL: 'https://chargeandgo-c73e1.firebaseio.com',
    projectId: 'chargeandgo-c73e1',
    storageBucket: 'chargeandgo-c73e1.appspot.com',
    messagingSenderId: '248462863828',
    appId: '1:248462863828:web:b5a91d3ed6acb5a9923a41',
    measurementId: 'G-EL68SJ8JDF'
  }
};
