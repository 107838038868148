import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as chartsData from '../../../shared/data/chartjs';
import {CssName, StatisticsService} from 'app/shared/services/statistics.service';
import { ChartModel } from 'app/models/chartModel';
import { ChartType, ChartEvent } from 'ng-chartist';
import * as Chartist from 'chartist'
declare var require: any;
const data: any = require('../../../shared/data/chartist.json');



export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  providers: [StatisticsService]
})
export class MainPageComponent implements OnInit, AfterViewInit {

  public areaChartData = chartsData.areaChartData;
  public areaChartLabels = chartsData.areaChartLabels;
  public areaChartOptions = chartsData.areaChartOptions;
  public areaChartColors = chartsData.areaChartColors;
  public areaChartLegend = chartsData.areaChartLegend;
  public areaChartType = chartsData.areaChartType;

  // Doughnut
  public doughnutChartData = chartsData.doughnutChartData;
  public doughnutChartType = chartsData.doughnutChartType;
  public doughnutChartColors;
  public doughnutChartOptions = chartsData.doughnutChartOptions;

  totalTime: string;
  totalPrice: string;
  totalCount: string;
  totalCountSuccess: string;
  totalEnergy: string;

  stationId : number;
  address : string;

  avgEnergy: string;
  avgH: string;
  avgM: string;
  avgPrice: string;

  actciv_info: string;
  busy_info: string;
  payment_info: string;
  charge_info: string;

  active_percent: number;
  bysy_percent: number;
  payment_percent: number;
  night_percent: number;

  WidgetlineChart: Chart = {
    type: 'Line', data: data['WidgetlineChart'],
    options: {
      axisX: {
        showGrid: true,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 40,
        showLabel: false,
        offset: 0,
      },
      lineSmooth: Chartist.Interpolation.cardinal({ tension: 0 }),
      fullWidth: true,
    },
  }

  DonutPayment: Chart = {
    type: 'Pie',
    data: data['DashboardDonut'],
    options: {
      donut: true,
      donutWidth: 6,
      startAngle: 0,
      chartPadding: 25,
    },
    events: {
      draw(data: any): void {
        if (data.type === 'slice') {
          const angle = data.endAngle - data.startAngle;
          const dur = angle / 360 * 1000;
          const pathLength = data.element._node.getTotalLength();
          data.element.attr({
            'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
          });
          const animationDefinition = {
            'stroke-dashoffset': {
              id: 'anim' + data.index,
              dur: dur,
              from: -pathLength + 'px',
              to: '0px',
              fill: 'freeze'
            }
          };

          if (data.index !== 0) {
            animationDefinition['stroke-dashoffset'].from = 'anim' + (data.index - 1) + '.end';
          }
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });
          data.element.animate(animationDefinition, false);
        }
        if (data.type === 'label') {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy: (data.element.root().height() + (data.element.height() / 4)) / 2,
            });
          } else {
            data.element.remove();
          }
        }
      }
    }
  }

  DonutNightCharging: Chart = {
    type: 'Pie',
    data: data['DashboardDonut'],
    options: {
      donut: true,
      donutWidth: 6,
      startAngle: 0,
      chartPadding: 25,
    },
    events: {
      draw(data: any): void {
        if (data.type === 'slice') {
          const angle = data.endAngle - data.startAngle;
          const dur = angle / 360 * 1000;
          const pathLength = data.element._node.getTotalLength();
          data.element.attr({
            'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
          });
          const animationDefinition = {
            'stroke-dashoffset': {
              id: 'anim' + data.index,
              dur: dur,
              from: -pathLength + 'px',
              to: '0px',
              fill: 'freeze'
            }
          };

          if (data.index !== 0) {
            animationDefinition['stroke-dashoffset'].from = 'anim' + (data.index - 1) + '.end';
          }
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });
          data.element.animate(animationDefinition, false);
        }
        if (data.type === 'label') {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy: (data.element.root().height() + (data.element.height() / 4)) / 2,
            });
          } else {
            data.element.remove()
          }
        }
      }
    }
  }

  DonutBusy: Chart = {
    type: 'Pie',
    data: data['DashboardDonut'],
    options: {
      donut: true,
      donutWidth: 6,
      startAngle: 0,
      chartPadding: 25,
    },
    events: {
      draw(data: any): void {
        if (data.type === 'slice') {
          const angle = data.endAngle - data.startAngle;
          const dur = angle / 360 * 1000;
          const pathLength = data.element._node.getTotalLength();
          data.element.attr({
            'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
          });
          const animationDefinition = {
            'stroke-dashoffset': {
              id: 'anim' + data.index,
              dur: dur,
              from: -pathLength + 'px',
              to: '0px',
              fill: 'freeze'
            }
          };

          if (data.index !== 0) {
            animationDefinition['stroke-dashoffset'].from = 'anim' + (data.index - 1) + '.end';
          }
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });
          data.element.animate(animationDefinition, false);
        }
        if (data.type === 'label') {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy: (data.element.root().height() + (data.element.height() / 4)) / 2,
            });
          } else {
            data.element.remove();
          }
        }
      }
    }
  }

  DonutActive: Chart = {
    type: 'Pie',
    data: data['DashboardDonut'],
    options: {
      donut: true,
      donutWidth: 6,
      startAngle: 0,
      chartPadding: 25,
    },
    events: {
      draw(data: any): void {
        if (data.type === 'slice') {
          const angle = data.endAngle - data.startAngle;
          const dur = angle / 360 * 1000;
          const pathLength = data.element._node.getTotalLength();
          data.element.attr({
            'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
          });
          const animationDefinition = {
            'stroke-dashoffset': {
              id: 'anim' + data.index,
              dur: dur,
              from: -pathLength + 'px',
              to: '0px',
              fill: 'freeze'
            }
          };

          if (data.index !== 0) {
            animationDefinition['stroke-dashoffset'].from = 'anim' + (data.index - 1) + '.end';
          }
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });
          data.element.animate(animationDefinition, false);
        }
        if (data.type === 'label') {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy: (data.element.root().height() + (data.element.height() / 4)) / 2,
            });
          } else {
            data.element.remove();
          }
        }
      }
    }
  }


  @ViewChild('canvas', { static: false }) myCanvas: ElementRef;
  public context: CanvasRenderingContext2D;


  constructor(private statService: StatisticsService) { }

  ngAfterViewInit() {
    this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d')
    const gradientOne = this.context.createLinearGradient(50, 0, 100, 400)
    gradientOne.addColorStop(0, 'rgba(0, 201, 255, 1)')
    gradientOne.addColorStop(1, 'rgba(146, 254, 157, 1)')
    this.areaChartColors = [{
      pointBackgroundColor: '#9062b3',
      pointBorderColor: 'rgba(48, 206, 171, 1)',
      pointHoverBackgroundColor: '#30ceab',
      pointHoverBorderColor: '#30ceab',
      pointHoverRadius: 5,
      pointBorderWidth: 2,
      backgroundColor: gradientOne
    }]

  }

  private getIChartistSeriesData(_firstCssNme: CssName, _secondCssName: CssName, count: number, total: number):
      Chartist.IChartistSeriesData[] {
    const chartSeriesData: Chartist.IChartistSeriesData[] =
        [{className: _firstCssNme, value: count},
          {className: _secondCssName, value: total}];
    return chartSeriesData;
  }

  ngOnInit() {

    console.log("1");
    if (window.location.href.indexOf('stationId') !== -1) {
      const u = new URL(window.location.href.replace("/#",""));
      this.stationId = Number(u.searchParams.get("stationId"));
      this.address = u.searchParams.get("address");
    } else {
      this.stationId = 0;
    }

    this.statService.getLastThirtyChargings(this.stationId)
        .subscribe(response => this.drawLineChart(response));

    //this.statService.getActivStations().subscribe(response => {
    //  this.actciv_info = response.data.series[1]['value'] + '/' + response.data.series[0]['value'];
    //  this.active_percent = response.percent;
    //  this.drawDonutCharts(response.data.series, this.active_percent, this.DonutActive);
    //});

    //this.statService.getPortsBusy().subscribe(response => {
    //  this.busy_info = response.data.series[1]['value'] + '/' + response.data.series[0]['value'];
    //  this.bysy_percent = response.percent;
    //  this.drawDonutCharts(response.data.series, this.bysy_percent, this.DonutBusy);
    //});


    //this.statService.getChargingCount().subscribe(response => {
    //  this.payment_info = response[0].data.series[1]['value'] + '/' + response[0].data.series[0]['value'];
    //  this.charge_info = response[1].data.series[1]['value'] + '/' + response[1].data.series[0]['value'];
    //  this.payment_percent = response[0].percent;
    //  this.night_percent = response[1].percent;
    //  this.drawDonutCharts(response[0].data.series, this.payment_percent, this.DonutPayment);
    //  this.drawDonutCharts(response[1].data.series, this.night_percent, this.DonutNightCharging);
    //});

    this.statService.getChargingFullStatistics(this.stationId).subscribe(response => {
      this.totalCountSuccess = response['total_count'];
      this.totalPrice = response['total_price'];
      this.totalTime = response['total_time'];
      this.totalEnergy = response['total_energy'];

      this.avgEnergy = parseFloat(String(response['total_energy'] / response['total_count'])).toFixed(2);
      this.avgPrice = parseFloat(String(response['total_price'] / response['total_count'])).toFixed(2);
      this.avgH = String( Math.trunc(response['avg_time'] / 60))
      this.avgM = String(response['avg_time'] % 60);
    });



    this.statService.getCurrentState()
        .subscribe(response => {
          this.busy_info = response['connectors_busy_count'] + '/' + response['connectors_count'];
          this.bysy_percent = Math.round( Number(Number(response['connectors_busy_count']) * 100 / Number( response['connectors_count'])) );

          this.actciv_info = response['stations_active_count'] + '/' + response['stations_count'];
          this.active_percent = Math.round( Number(Number(response['stations_active_count']) * 100 / Number( response['stations_count'])) );
          const model1: ChartModel = {
            percent:  this.active_percent,
            data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding,
                  response['stations_count'],
                  response['stations_active_count'])}
          }

          this.drawDonutCharts(model1.data.series, this.active_percent, this.DonutActive);

          const model2: ChartModel = {
            percent:  this.active_percent,
            data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding,
                  response['connectors_count'],
                  response['connectors_busy_count'])}
          }

          this.drawDonutCharts(model2.data.series, this.bysy_percent, this.DonutBusy);
        });

    this.statService.getChargingTotalStatistic(this.stationId)
        .subscribe(response => {
          this.payment_info =  response['charging_session_paid_count'] + '/' + response['charging_session_count'];
          this.charge_info = response['charging_session_night_count'] + '/' + response['charging_session_count'];
          this.payment_percent =  Math.round( Number(Number(response['charging_session_paid_count']) * 100 / Number( response['charging_session_count'])) );
          this.night_percent =  Math.round( Number(Number(response['charging_session_night_count']) * 100 / Number( response['charging_session_count'])) );

          this.totalCount = response['charging_session_count'];
          const model1: ChartModel = {
            percent:  this.payment_percent,
            data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding,
                  response['charging_session_count'],
                  response['charging_session_paid_count'])}
          }

          this.drawDonutCharts(model1.data.series, this.payment_percent, this.DonutPayment);

          const model2: ChartModel = {
            percent:  this.night_percent,
            data: {series: this.getIChartistSeriesData(CssName.Done, CssName.Outstanding,
                  response['charging_session_count'],
                  response['charging_session_night_count'])}
          }

          this.drawDonutCharts(model2.data.series, this.night_percent, this.DonutNightCharging);
        });
  }

  drawLineChart(value: ChartModel) {
    this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d')
    this.areaChartData = [{ data: value.data.series }]
    this.areaChartLabels = value.data.labels
  }

  drawDonutCharts(data: any, percent: number, chart: Chart) {
    console.log(data)
    console.log(percent)
    data[0].value = percent;
    data[1].value = 100 - percent;
    chart.type = 'Pie';
    chart.data = { series: data }
    chart.options = {
      donut: true,
      donutWidth: 12,
      startAngle: 0,
      chartPadding: 25,
      labelInterpolationFnc: function (value) {
        return percent + '%';
      },
    }
  }
}




