import { Component, OnInit, Input } from '@angular/core';
import { WorkWithPortService } from 'app/shared/services/work-with-potr.service';
import { Station } from 'app/shared/services/stations-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  providers: [WorkWithPortService]
})
export class TableViewComponent implements OnInit {

  @Input() paginArray: Station[]
  @Input() onlyNotActive: boolean

  isAdmin = 'oper'

  constructor(private router: Router,
    public services: WorkWithPortService) { }

  ngOnInit() {
    this.isAdmin = window.localStorage.getItem('Role')
  }

  selectedStation(_station: Station) {
    this.router.navigate(['station-page', _station.id]);
  }

}
