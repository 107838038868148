import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContentPagesRoutingModule} from './content-pages-routing.module';
import {LoginPageComponent} from './login/login-page.component';
import {StationCharactComponent} from './station-charact/station-charact.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UiSwitchModule} from 'ngx-ui-switch';
import {MatDialogModule, MatAutocompleteModule} from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip'
import {AgmCoreModule} from '@agm/core';
import {ProfileComponent} from './profile/profile.component'
import {NgInitDirective} from 'app/shared/directives/ng-init.directive';
import {TooltipImgModule} from 'app/shared/directives/tooltip-img.directive';
import {StationPagesComponent} from './station-pages/station-pages.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {MainPageComponent} from './main-page/main-page.component';
import {ChartistModule} from 'ng-chartist';
import {MatchHeightModule} from '../../shared/directives/match-height.directive';
import {ChartsModule} from 'ng2-charts';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {JwPaginationComponent} from 'jw-angular-pagination';
import {EditStationComponent} from './edit-station/edit-station.component';
import {CustomPaginationComponent} from './custom-pagination/custom-pagination.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {AngularYandexMapsModule} from 'angular8-yandex-maps';
import {AddNewUserComponent} from './add-new-user/add-new-user.component'
import {TranslateModule} from '@ngx-translate/core';
import {ConnectorComponent} from './connector/connector.component';
import {AddNewStationComponent} from './add-new-station/add-new-station.component';
import {TableViewComponent} from './table-view/table-view.component';
import {SharedModule} from 'app/shared/shared.module';
import {FilterComponent} from './filter/filter.component';
import {TransactionsComponent} from './transactions/transactions.component';
import {UserPanelComponent} from './user-panel/user-panel.component';
import {BalanceComponent} from './balance/balance.component';
import {MatCheckboxModule} from '@angular/material/checkbox';


@NgModule({
    imports: [
        MatExpansionModule,
        CommonModule,
        ContentPagesRoutingModule,
        FormsModule,
        NgbModule,
        UiSwitchModule,
        MatDialogModule,
        AgmCoreModule,
        TooltipImgModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ChartistModule,
        MatchHeightModule,
        ChartsModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatTableModule,
        MatPaginatorModule,
        AngularYandexMapsModule,
        MatAutocompleteModule,
        TranslateModule,
        SharedModule,
        MatCheckboxModule
    ],
    declarations: [
        LoginPageComponent,
        StationCharactComponent,
        ProfileComponent,
        NgInitDirective,
        StationPagesComponent,
        StatisticsComponent,
        MainPageComponent,
        EditStationComponent,
        JwPaginationComponent,
        CustomPaginationComponent,
        AddNewUserComponent,
        ConnectorComponent,
        AddNewStationComponent,
        TableViewComponent,
        FilterComponent,
        TransactionsComponent,
        UserPanelComponent,
        BalanceComponent
    ],
})
export class ContentPagesModule {
}
