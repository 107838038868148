import {Component, OnInit, Renderer2, ViewChild, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms'
import { StationsInfoService, Station } from 'app/shared/services/stations-info.service';
import { GetStationInfoServices } from 'app/shared/services/get-data-from-other-component.service';
import { MatFormFieldControl} from '@angular/material/form-field'

@Component({
  selector: 'app-edit-station',
  templateUrl: './edit-station.component.html',
  styleUrls: ['./edit-station.component.scss']
})
export class EditStationComponent implements OnInit {

  @ViewChild('editor', { static: false }) editor: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef
  @Input() loadingImageRef: ElementRef<HTMLElement>
  isOpen = false
  imgArray: string[]
  myForm: FormGroup
  station: Station
  utc: IUTC[] = [
    { id: 0, utc: '(+00:00 UTC)' },
    { id: 1, utc: '(+01:00 UTC)' },
    { id: 2, utc: '(+02:00 UTC)' },
    { id: 3, utc: '(+03:00 UTC)' },
    { id: 4, utc: '(+04:00 UTC)' },
    { id: 5, utc: '(+05:00 UTC)' },
    { id: 6, utc: '(+06:00 UTC)' },
    { id: 7, utc: '(+07:00 UTC)' },
    { id: 8, utc: '(+08:00 UTC)' },
    { id: 9, utc: '(+09:00 UTC)' },
    { id: 10, utc: '(+10:00 UTC)' },
    { id: 11, utc: '(+11:00 UTC)' },
    { id: 12, utc: '(+12:00 UTC)' },
    { id: 13, utc: '(+13:00 UTC)' },
    { id: 14, utc: '(+14:00 UTC)' },
    { id: -1, utc: '(-01:00 UTC)' },
    { id: -2, utc: '(-02:00 UTC)' },
    { id: -3, utc: '(-03:00 UTC)' },
    { id: -4, utc: '(-04:00 UTC)' },
    { id: -5, utc: '(-05:00 UTC)' },
    { id: -6, utc: '(-06:00 UTC)' },
    { id: -7, utc: '(-07:00 UTC)' },
    { id: -8, utc: '(-08:00 UTC)' },
    { id: -9, utc: '(-09:00 UTC)' },
    { id: -10, utc: '(-10:00 UTC)' },
    { id: -11, utc: '(-11:00 UTC)' },
    { id: -12, utc: '(-12:00 UTC)' }
  ]
  currency = [
    'USD',
    'EUR',
    'BYN',
    'RUB',
    'PLN',
    'NOK',
    'GBP']
  visibleCheckBox = false
  canControl = false

  constructor(private renderer: Renderer2,
    private stationService: StationsInfoService,
    private getValueServices: GetStationInfoServices) { }


  ngOnInit() {
    this.getValueServices.myMethod$.subscribe(_station => {
      this.imgArray = _station.images ? _station.images.map(img => img.url) : []

      this.station = _station
      console.log(this.station)
      let power
      if (this.station.type === 'Mode3' || this.station.type === 'Mode3x2') {
        power = this.station.powerAC
      } else {
        power = this.station.powerDC
      }
      this.myForm = new FormGroup({
        name: new FormControl({ value: _station.name, disabled: true }),
        powerAC: new FormControl(_station.powerAC),
        power: new FormControl(power),
        country: new FormControl(_station.country),
        city: new FormControl(_station.city),
        street: new FormControl(_station.street),
        currency: new FormControl(_station.currency.toUpperCase()),
        ip: new FormControl(_station.ip),
        port: new FormControl(_station.port),
        latitude: new FormControl(_station.latitude),
        longitude: new FormControl(_station.longitude),
        priceMin: new FormControl(_station.priceMin),
        price: new FormControl(_station.price),
        type: new FormControl({ value: _station.type, disabled: true }),
        mapUrl: new FormControl(),
        serial: new FormControl(_station.serial),
        utc: new FormControl(this.utc.find(u => u.id === _station.utc)),
        protocol: new FormControl({ value: _station.protocol, disabled: true }),
        company: new FormControl(_station.company)
      })
      this.visibleCheckBox = !!_station.visible
      this.canControl = !!_station.canControl
    })
  }

  submit() {
    console.log("Submit");
    if (this.myForm.get('ip').value !== '' && this.myForm.get('type').value !== '') {
      let powerAC
      let powerDC
      if (this.station.type === 'Mode4Combo') {
        powerAC = +this.myForm.get('powerAC').value
        powerDC = +this.myForm.get('power').value
      } else if (this.station.type === 'Mode3' || this.station.type === 'Mode3x2') {
        powerAC = +this.myForm.get('power').value
        powerDC = 0
      } else {
        powerAC = 0
        powerDC = +this.myForm.get('power').value
      }
      const newArray = {
        id: this.station.id,
        country: this.myForm.get('country').value,
        city: this.myForm.get('city').value,
        street: this.myForm.get('street').value,
        company: this.myForm.get('company').value,
        currency: this.myForm.get('currency').value,
        ip: this.myForm.get('ip').value,
        port: +this.myForm.get('port').value,
        utc: this.utc.find(u => u === this.myForm.get('utc').value).id,
        powerAC,
        powerDC,
        price: +this.myForm.get('price').value,
        priceMin: +this.myForm.get('priceMin').value,
        latitude: +this.myForm.get('latitude').value,
        longitude: +this.myForm.get('longitude').value,
        images: this.imgArray,
        visible: this.visibleCheckBox ? 1 : 0,
        canControl: this.canControl ? 1 : 0
      }
      console.log(newArray)
      this.stationService.updateStation(newArray);
    }
  }

  changeCheckBox(name: string, value: boolean) {
    if (name === 'visible') {
      this.visibleCheckBox = value
    } else {
      this.canControl = value
    }
  }

  toggleEditor() {
    if (this.isOpen) {
      this.renderer.removeClass(this.editor.nativeElement, 'open');
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.editor.nativeElement, 'open');
      this.isOpen = true;
    }
  }

  open() {
    this.renderer.setStyle(this.editor.nativeElement,'visibility', 'visible')
  }

  showLoadingImage() {
    this.renderer.setStyle(this.loadingImageRef.nativeElement, 'visibility', 'visible')
  }

  close() {
    this.renderer.setStyle(this.editor.nativeElement,'visibility', 'hidden')
  }

  loadStationImage(e) {
    const reader = new FileReader();
    reader.onload = (event: Event) => {
      this.imgArray.push(event.target['result']);
    }
    reader.readAsDataURL(e.srcElement.files[0]);
  }

  removeImg(src: string) {
    this.imgArray = this.imgArray.filter(img => img !== src)
  }
}

interface IUTC {
  id: number,
  utc: string
}
