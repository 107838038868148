import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StationCharactComponent } from './station-charact/station-charact.component'
import { ProfileComponent } from './profile/profile.component';
import { StationPagesComponent } from './station-pages/station-pages.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { MainPageComponent } from './main-page/main-page.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { TransactionsComponent } from './transactions/transactions.component';
import {BalanceComponent} from './balance/balance.component';



const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MainPageComponent,
        data: {
          title: 'Main'
        }
      },
      {
        path: 'station-charact',
        component: StationCharactComponent,
        data: {
          title: 'Station Charact'
        }
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: 'User Profile'
        }
      },
      {
        path: 'station-page/:id',
        component: StationPagesComponent,
        data: {
          title: 'Station Page'
        }
      },
      {
        path: 'statistics',
        component: StatisticsComponent,
        data: {
          title: 'Statistics'
        }
      },
      {
        path: 'addNewUser',
        component: AddNewUserComponent,
        data: {
          title: 'Add new user'
        }
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: {
          title: 'Transactions'
        }
      },
      {
        path: 'balance',
        component: BalanceComponent,
        data: {
          title: 'Balance'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentPagesRoutingModule { }
