import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SubjectService } from 'app/shared/services/subject.service';


@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
})

export class LoginPageComponent {

    login = '';
    password = '';

    constructor(private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private toastrService: ToastrService,
        private sub: SubjectService ,
        private translate: TranslateService) { }


    onSubmit() {
        if (this.password || this.login) {
            this.authService.signinUser(this.login, this.password).subscribe(data => {
                if (data) {
                    console.log(data)
                    this.authService.getUserData().subscribe(data2 => {
                        if (data2) {
                            this.router.navigate(['']);
                        }
                    });

                } else {
                    this.login = ''
                    this.password = ''
                }
            });
        } else { this.toastrService.error(this.translate.instant('reqredValidationSignIn')) }
    }

    ChangeLanguage(language: string) {
        window.localStorage.setItem('Language', language);
        this.translate.use(language);
        this.sub.changeLanguage(language)
      }

    onForgotPassword() {
        this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
    }

    onRegister() {
        this.router.navigate(['register'], { relativeTo: this.route.parent });
    }
}
